import { useMemo } from 'react'

import { GqlTableColumn } from '../../../table'
import { getNestedValue } from '../../../utils';
import { ReportGroupOptions, ReportOptionFormat } from '../types'

type Summary = (
  data: any[],
  currentData: any[],
  index: number,
  noFormat?: boolean
) => { value?: React.ReactNode; style?: React.CSSProperties } | React.ReactNode

type AgregatorMethod = (field: string, param?: string) => Summary

const style = { fontSize: 14, paddingLeft: 8, paddingRight: 8, paddingTop: 2, paddingBottom: 1 }

const SUMMARY_AGGREGATORS: Record<ReportOptionFormat, AgregatorMethod> = {
  [ReportOptionFormat.TEXT]: (field: string, param?: string) => (data, _curData, _index, noFormat) => {
    const value = data.joinOf(param ?? ', ', (it) => getNestedValue(it, field))

    if (noFormat) return value

    return { value, style }
  },
  [ReportOptionFormat.QUANTITY]: (field: string, param?: string) => (data, _curData, _index, noFormat) => {
    const decimals = (param && parseInt(param)) || 3
    const value = data.sumOf((it) => getNestedValue(it, field) ?? 0)

    if (noFormat) return value

    return { value: value.toLocaleString('en-DE', { maximumFractionDigits: decimals }), style }
  },
  [ReportOptionFormat.PRICE]: (field: string, param?: string) => (data, _curData, _index, noFormat) => {
    const value = data.sumOf((it) => getNestedValue(it, field) ?? 0)

    if (noFormat) return value

    return { value: value.formatCurrency(param ?? 'EUR'), style }
  }
}

interface ReportGroupOptionAggregator extends ReportGroupOptions {
  aggregate: Summary
}

export const useReportColumnsWithSummary = (columns: GqlTableColumn<any>[], summaryOptions?: ReportGroupOptions[]) => {
  const selectedOptions = useMemo(() => {
    if (!summaryOptions?.length) return undefined

    const selectedOption = summaryOptions.filter((it) => it.selected)

    if (!selectedOption.length) return undefined

    return selectedOption.toMapBy<string, ReportGroupOptionAggregator>(
      (it) => it.name,
      (it) => {
        const createAggregator = SUMMARY_AGGREGATORS[it.format] ?? (() => () => 'n/a')
        return { ...it, aggregate: createAggregator(it.name, it.param) }
      }
    )
  }, [summaryOptions])

  return useMemo(() => {
    if (!selectedOptions) return columns

    return columns.map((it) => {
      const summaryOption = selectedOptions[it.fieldName]

      if (!summaryOption) return it

      return {
        ...it,
        summary: summaryOption.aggregate
      }
    })
  }, [columns, selectedOptions])
}
