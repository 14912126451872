import React, { MutableRefObject, useCallback, useEffect, useMemo } from 'react'

import DnsRoundedIcon from '@mui/icons-material/DnsRounded'
import FilterAltIcon from '@mui/icons-material/FilterAltOutlined'
import FunctionsIcon from '@mui/icons-material/Functions'
import { Box, Stack } from '@mui/material'

import { FeatureStateProvider, GqlReportGroupOptionsList } from './features'
import { useReportGroupOptions } from './features/useReportGroupOptions'
import { GqlReportFiltersContext, useReportFilters } from './filters'
import { GqlReportFilters } from './filters/GqlReportFilters'
import { useReportItemGroups } from './group/useReportItemGroups'
import { PlainTableMemo } from './PlainTableMemo'
import { useReportColumnsWithSummary } from './summary'
import { ReportGqlQuery } from './types'
import { useReportFieldsFilter } from './useReportFieldsFilter'
import { useReportGql } from './useReportGql'
import { useZeroApiContext } from '../../context'
import { useProcessFieldsAsColumns } from '../../gql-fields'
import { GqlTableColumn, PlainTableProps } from '../../table'
import { useCurrentLocale } from '../../translations'
import { FormQueryProps } from '../../types'
import { setNestedValue } from '../../utils'

type TreeItemsLookup = (row: any, rows: any[]) => any

const treeItemsGroupLookup = (row, rows) => {
  return row?._parentKey && rows && rows.find((it) => it?._key && it._key === row._parentKey)
}

export interface GqlReportProps extends FormQueryProps, Omit<PlainTableProps<any>, 'items' | 'columns'> {
  treeLookup?: (row: any, parent: any) => boolean
  rowData?: any
  refItems?: MutableRefObject<any[]>
  remapItems?: (items: any[]) => any[]
  queryRef?: MutableRefObject<ReportGqlQuery<any> | undefined>
}

export const GqlZeroReport: React.FC<GqlReportProps> = (props) => {
  const context = useZeroApiContext()

  const parentRowData = props.rowData

  const getParentValue = useCallback(
    (value: string | ((rowData: any) => any) | undefined) => {
      if (parentRowData === undefined || value === undefined) return undefined
      if (value instanceof Function) return value(parentRowData)
      return parentRowData[value]
    },
    [parentRowData]
  )

  const propsFilters = useReportFieldsFilter(props)
  const selectedFilters = useReportFilters()

  const filters = useMemo(() => ({ ...propsFilters, ...selectedFilters }), [propsFilters, selectedFilters])

  const { fieldsMap, query } = useReportGql<any>({
    ...props,
    filters,
    customQuery: props.entity,
    list: true,
    all: props.all,
    readOnly: props.readOnly || props.aggregated,
    entityRelFieldName: props.entityRelFieldName || `${context.name.toLocaleLowerCase()}Id`,
    entityRelFieldValue: props.entityRelFieldValue || context.id,
    getParentValue
  })

  if (props.queryRef) {
    props.queryRef.current = query
  }

  const [reportSummaryFields, updateSummaryFields] = useReportGroupOptions(query.data?.subTotals)
  const [reportGroupFields, updateGroupFields] = useReportGroupOptions(query.data?.group)

  const { columns, columnGetValues, detailPanel, aggregateColumns } = useProcessFieldsAsColumns(props, fieldsMap, query.refresh)

  const reportHeaders = query.data?.headers

  const columnsWithHeader = useMemo(() => {
    if (!reportHeaders?.length) return columns

    const columnsMap = columns.toMapBy<string, GqlTableColumn<any>>((it) => it.fieldName)

    return reportHeaders
      .flatMap((header) => {
        const column = columnsMap[header.name]

        const headerFontSizeRaw = column?.headerStyle?.fontSize
        const headerFontSize: number = typeof headerFontSizeRaw === 'number' ? Number.parseFloat(headerFontSizeRaw.toString()) : 12

        const columnHeader: GqlTableColumn<any> | null = column
          ? {
              ...column,
              hidden: column.hidden || header?.hidden,
              title: header.title,
              headerStyle: {
                ...column.headerStyle,
                whiteSpace: 'pre-line',
                fontWeight: 600,
                fontSize: headerFontSize + 1
              }
            }
          : null

        if (!header.children.length) return columnHeader

        const childColumnHeaders = header.children.map<GqlTableColumn<any> | null>((childHeader) => {
          const childColumn = columnsMap[childHeader.name]

          const baseColumn = childColumn ?? {
            ...column,
            field: childHeader.name,
            fieldName: childHeader.name,
            // eslint-disable-next-line no-useless-call
            render: column.render ? (row: any) => column.render?.call?.(column, row, childHeader.name.split('.')) : undefined
          }

          return {
            ...baseColumn,
            groupName: header.title,
            groupTitle: header.title,
            hidden: baseColumn.hidden || childHeader?.hidden,
            title: childHeader.title,
            headerStyle: {
              ...baseColumn.headerStyle,
              whiteSpace: 'pre-line',
              fontWeight: 600,
              fontSize: headerFontSize + 1
            }
          }
        })

        return [columnHeader, ...childColumnHeaders]
      })
      .filterNotNull()
  }, [columns, reportHeaders])

  const groups = columnsWithHeader.mapDistinct((it) => it.groupName).filterNotNull()

  const columnsWithSammary = useReportColumnsWithSummary(columnsWithHeader, reportSummaryFields)

  const aggregatorColumns = useReportItemGroups(reportGroupFields)

  const remapItems = props.remapItems

  const reportItems = query.data?.results

  const [items, treeItemsLookup] = useMemo<[any[], TreeItemsLookup | undefined]>(() => {
    let result = reportItems ?? []

    if (aggregatorColumns?.length && result.length > 0) {
      const columnValueGetters = columnGetValues.toMapBy(
        (it) => it.fieldPathName,
        (it) => it.getValue
      )

      const agrItems = result.groupBy((item) => {
        return aggregatorColumns.joinOf('|', (column) => columnValueGetters[column](item))
      })

      result = Object.entries(agrItems).flatMap(([key, items]) => {
        const agrItem = { _key: key, _agrItems: items }

        aggregatorColumns.forEach((column) => {
          agrItem[column] = items[0][column]
        })

        columnsWithSammary.forEach((column, index) => {
          if (!column.summary) return
          setNestedValue(agrItem, column.fieldName, column.summary(items, items, index, true))
        })

        aggregateColumns.forEach(([column, aggregator]) => {
          agrItem[column] = aggregator(items)
        })

        return items.map((it) => ({ ...it, _parentKey: key })).concat(agrItem)
      })

      return [result, treeItemsGroupLookup]
    }

    if (remapItems) {
      return [remapItems(result), undefined]
    }

    return [result, undefined]
  }, [reportItems, aggregatorColumns, remapItems, columnGetValues, columnsWithSammary, aggregateColumns])

  if (props.refItems) props.refItems.current = items

  useEffect(() => {
    if (props.onItems) props.onItems(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onItems ? JSON.stringify(items) : items.length])

  const searchQuery = props.search ?? new URL(window.location.href).searchParams.get('q') ?? undefined

  const lang = useCurrentLocale()

  return (
    <Box>
      <Stack direction="column" spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Stack direction="column" spacing={1} sx={{ mt: 1, mb: 1 }}>
          <GqlReportFilters report={query.data} icon={<FilterAltIcon fontSize="medium" sx={{ mr: 1 }} />} />
          <GqlReportGroupOptionsList
            name="subTotals"
            icon={<FunctionsIcon fontSize="medium" sx={{ mr: 1 }} />}
            options={reportSummaryFields}
            onChange={updateSummaryFields}
          />
          <GqlReportGroupOptionsList
            name="grouping"
            icon={<DnsRoundedIcon fontSize="medium" sx={{ mr: 1 }} />}
            options={reportGroupFields}
            onChange={updateGroupFields}
          />
        </Stack>

        <PlainTableMemo
          {...props}
          key={treeItemsLookup ? 'tree' : 'flat'}
          lang={lang}
          elevation={6}
          search={searchQuery}
          style={props.style}
          onItemClick={props.onItemClick}
          title={query.data?.title ?? props.title ?? props.entity}
          isLoading={query.isLoading}
          columns={columnsWithSammary}
          items={items}
          treeItemsLookup={treeItemsLookup}
          detailPanel={detailPanel}
          options={{ ...props.options, tableLayout: 'auto' }}
          headerGroupStyles={groups.toMapBy(
            (it) => it,
            () => ({ whiteSpace: 'pre-line', fontWeight: 600 })
          )}
        />
      </Stack>
    </Box>
  )
}

export const GqlReport: React.FC<GqlReportProps> = (props) => {
  return (
    <FeatureStateProvider context={GqlReportFiltersContext}>
      <GqlZeroReport {...props} />
    </FeatureStateProvider>
  )
}
