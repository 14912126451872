import { ApolloQueryResult } from '@apollo/client'

export interface GqlZeroReport<T extends object> {
  filters: ReportFilter[]
  headers: ReportGroupOptions[]
  group: ReportGroupOptions[]
  subTotals?: ReportGroupOptions[]
  totalsGroup: any[] | null
  title: string
  documentHeaders: DocumentHeader[]
  results: (T & ReportResults)[]
}

export interface ReportResults extends Record<string, string> {}

export interface DocumentHeader {
  value: string
  title: string
}

export enum ReportOptionFormat {
  TEXT = 'TEXT',
  PRICE = 'PRICE',
  QUANTITY = 'QUANTITY'
}

export interface ReportGroupOptions {
  name: string
  title: string
  param?: string
  hidden: boolean
  selected: boolean
  format: ReportOptionFormat
  children: ReportGroupOptions[]
}

export interface ReportFilter {
  name: string
  title: string
  options: ReportFilterOption[]
  selection: string[]
}

export interface Selection extends Record<string, string[]> {}

export interface ReportFilterOption {
  id: string
  title: string
}

export interface ReportGqlQuery<T> {
  isLoading: boolean
  data?: T

  refresh(variables?: Partial<T>): Promise<ApolloQueryResult<T>>
}
