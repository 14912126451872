import React, { useMemo } from 'react'

import { CompositeField, FilterField } from '../fields'
import { FormQueryProps, GqlComponent, GqlFilter, GqlTabletField } from '../types'
import { callOrGet } from '../utils'

export function useEntityFieldsFilter(props: FormQueryProps): GqlFilter | undefined {
  const aggregated = props.aggregated ?? false

  return useMemo(() => {
    let filter = props.filter

    const loadFieldMap = (childElement?: JSX.Element | React.ReactElement<GqlTabletField<any>, GqlComponent<GqlTabletField<any>>>) => {
      if (!childElement || childElement.type === 'div') return

      const child = childElement as React.ReactElement<GqlTabletField<any>, GqlComponent<GqlTabletField<any>>>
      const childProps = child.props

      if (aggregated && childProps.ignoreOnAggregate) return
      if (!aggregated && childProps.ignoreOnNonAggregate) return

      if (child.type === CompositeField) {
        React.Children.forEach(child.props.children, loadFieldMap)
        return
      }

      const gqlType = childProps.gql !== undefined ? childProps.gql : child.type.gql
      // eslint-disable-next-line no-console
      if (!gqlType) console.warn('gql-type not defined in ', child.type.name)

      if (child.type === FilterField) {
        const value =
          childProps.valueFromParent && props.getParentValue
            ? callOrGet(props.getParentValue, childProps.valueFromParent)
            : childProps.value
        if (filter) {
          filter = { ...filter, and: { by: childProps.name, eqStr: value } }
        } else {
          filter = { by: childProps.name, eqStr: value }
        }
      }
    }

    React.Children.forEach(props.children, loadFieldMap)

    return filter
  }, [aggregated, props.children, props.filter, props.getParentValue])
}
