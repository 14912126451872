import React from 'react'

import { GqlForm } from './GqlForm'
import { useTranslation } from '../../translations'
import { GqlFilter } from '../../types'
import { AppDialog } from '../../utils'


interface FormDialogProps {
  children: JSX.Element[]
  title?: string
  entity?: string
  customQuery?: string
  relFieldName?: string
  item: boolean | string
  onClose: (t: any) => void
  onRefresh?: (data: any) => Promise<any> | undefined
  autoClose?: boolean
  autoRefresh?: boolean
  filter?: GqlFilter
  keys?: string[]
  args?: any
  noDelete?: boolean
  all?: boolean
}

export const FormDialog: React.FC<FormDialogProps> = ({
  children,
  title,
  entity,
  customQuery,
  relFieldName,
  item,
  args,
  onClose,
  onRefresh,
  autoRefresh,
  autoClose,
  filter,
  keys,
  noDelete,
  all
}) => {
  const translate = useTranslation()

  const dialogTitle = title || entity?.replaceAll(/([A-Z][a-z0-9]+)/g, (v) => '_' + v.toLowerCase())?.substring(1) || customQuery || ''

  const onAutoClose = autoClose ? onClose : undefined

  const itemId = item && item === item.toString() ? item : undefined

  return (
    <AppDialog title={translate(dialogTitle)} open={Boolean(item)} onClose={() => onClose(false)}>
      <GqlForm
        args={args}
        loadingVariant="circular"
        entity={entity ?? customQuery ?? ''}
        customQuery={customQuery}
        entityRelFieldName={relFieldName || 'id'}
        entityRelFieldValue={itemId}
        refresh={onRefresh}
        onSave={onAutoClose}
        onDelete={onAutoClose}
        filter={filter}
        keys={keys}
        autoRefresh={autoRefresh}
        noDelete={noDelete}
        all={all}
      >
        {children}
      </GqlForm>
    </AppDialog>
  )
}
