import React, { PropsWithChildren, useMemo } from 'react'

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { ThemeOptions, createTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { ZeroApiContext, ZeroApiContextInfo } from './ZeroApiContext'

export type { Theme as ZeroTheme }

export const createMuiTheme: (options?: ThemeOptions) => Theme = (options) => createTheme(options)

interface Props extends PropsWithChildren<Omit<ZeroApiContextInfo, 'lang'>> {
  lang?: string
  theme: Theme
}

export const ZeroApiContextProvider: React.FC<Props> = (props) => {
  const cache = useMemo(() => {
    return new InMemoryCache({
      typePolicies: {
        ReportFilterOptions: {
          keyFields: ['_id']
        },
        ProfileByEmail: {
          keyFields: ['_id']
        }
      }
    })
  }, [])

  const link = useMemo(() => {
    return new HttpLink({
      uri: props.apiUrl,
      credentials: 'include',
      fetchOptions: {
        credentials: 'include'
      }
    })
  }, [props.apiUrl])

  const client = useMemo(() => new ApolloClient({ cache, link }), [cache, link])

  return (
    <ThemeProvider theme={props.theme}>
      <ApolloProvider client={client}>
        <ZeroApiContext.Provider key={props.lang} value={{ ...props, lang: props.lang ?? 'mk' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {React.Children.map(props.children, (child) => {
              if (!React.isValidElement(child)) return null
              return React.cloneElement(child, { ...props, ...child.props })
            })}
          </LocalizationProvider>
        </ZeroApiContext.Provider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
