import { useEntityQuery } from './useEntityQuery'
import { useZeroApiContext } from '../context'
import { Field, QueryOptions } from '../types'


export function useEntityRelationQuery<T>(
  entity: string,
  fields: Field<any>[],
  relField?: string,
  relValue?: string,
  options?: QueryOptions
) {
  const context = useZeroApiContext()
  const field = relField !== undefined ? relField : `${context.name.toLowerCase()}Id`
  const value = relField !== undefined ? relValue : context.id
  return useEntityQuery<T>(entity, fields, field, value, options)
}

export function useEntityContextQuery<T>(entity: string, fields: Field<any>[], options?: QueryOptions) {
  const context = useZeroApiContext()
  const field = `${context.name.toLowerCase()}Id`
  return useEntityQuery<T>(entity, fields, field, context.id, options)
}
