import React from 'react'

import { CompositeField, FilterField, GqlDetailPanel, GqlFormTable, HiddenField, NestedItemForm, TranslationField } from '../fields'
import { FieldMap, FormField, GqlComponent, GqlFilter, GqlTabletField } from '../types'
import { callOrGet, ifNull, uid } from '../utils'

export const getEntityFieldsMap = (
  children: React.ReactElement<FormField<any>>[],
  entityKeys?: string[],
  relName?: string,
  relValue?: string,
  isAggregated?: boolean,
  getParentValue?: ((rowData: any) => any) | undefined,
  userFilter?: GqlFilter,
  lang?: string
): FieldMap => {
  const language = lang?.toLocaleLowerCase()
  const keys = entityKeys ?? ['id']

  const aggregated = isAggregated ?? false

  const fieldsMap: FieldMap = {}

  keys.forEach((key) => {
    fieldsMap[key] = {
      name: key,
      label: key,
      gql: 'String!',
      id: key === 'id',
      value: relName === key ? relValue : uid(),
      virtual: true
    }
  })

  fieldsMap._id = {
    name: '_id',
    label: '_id',
    gql: 'String!',
    readOnly: true,
    virtual: true
  }

  let filter = userFilter

  const loadFieldMap =
    (root: FieldMap) => (childElement?: JSX.Element | React.ReactElement<GqlTabletField<any>, GqlComponent<GqlTabletField<any>>>) => {
      // if (child.type.fields) {
      //   console.log(childProps.name)
      //   callOrGet(child.type.fields, childProps, child.props.param).forEach(loadFieldMap(root))
      //   return
      // }

      if (!childElement || childElement.type === 'div') return

      const child = childElement as React.ReactElement<GqlTabletField<any>, GqlComponent<GqlTabletField<any>>>
      const childProps = child.props

      if (aggregated && childProps.ignoreOnAggregate) return
      if (!aggregated && childProps.ignoreOnNonAggregate) return

      if (child.type === CompositeField) {
        React.Children.forEach(childProps.children, loadFieldMap(root))
        root[childProps.name] = {
          name: childProps.name,
          virtual: true
        }
        return
      } else if (child.type === TranslationField) {
        if (language) {
          root[childProps.name] = {
            name: childProps.name,
            virtual: true,
            childs: {
              [language]: { name: language, gql: 'String' }
            }
          }
        }
        return
      }

      const gqlType = childProps.gql !== undefined ? childProps.gql : child.type.gql
      // eslint-disable-next-line no-console
      if (!gqlType) console.warn('gql-type not defined in ', child.type.name)

      const fieldName = child.type === TranslationField ? `${childProps.name}` : childProps.name

      const field: FormField<any> = {
        id: childProps.id,
        name: fieldName,
        language: lang,
        label: childProps.label,
        default: childProps.default,
        convert: childProps.convert || child.type.convert,
        value: childProps.value,
        forceValue: childProps.forceValue,
        hidden: child.type === HiddenField || callOrGet(childProps.hidden),
        gql: gqlType ? (childProps.nullable ? gqlType : `${gqlType}!`) : undefined,
        nullable: childProps.nullable,
        valueFromParent: childProps.valueFromParent,
        targetField: childProps.targetField,
        readOnly: ifNull(childProps.transitient, childProps.readOnly, child.type.readOnly) ?? false,
        derived: childProps.derived,
        summary: childProps.summary
      }

      if (child.type === NestedItemForm) {
        const childKeys = childProps.keys ?? ['id']
        field.nested = true
        field.dependent = childProps.dependent
        field.keys = childKeys
        if (field.gql == null) {
          field.gql = field.name.capitalize()
        }
        field.readOnly = ifNull(childProps.transitient, childProps.readOnly, child.type.readOnly) ?? false
        field.gqlCreate = `create${childProps.name.capitalize()}`
        field.childs = childKeys.toMapBy(
          (it) => it,
          (it) => ({ id: true, name: it, gql: 'String!' } as FormField<any>)
        )
        React.Children.forEach(childProps.children, loadFieldMap(field.childs))
      } else if (child.type === FilterField) {
        const value =
          childProps.valueFromParent && getParentValue ? callOrGet(getParentValue, childProps.valueFromParent) : childProps.value
        if (filter) {
          filter = { ...filter, and: { by: fieldName, eqStr: value } }
        } else {
          filter = { by: fieldName, eqStr: value }
        }
      } else if (child.type === GqlDetailPanel) {
        return
      } else if (child.type === GqlFormTable) {
        const childKeys = childProps.keys ?? ['id']
        field.nested = true
        field.list = true
        field.aggregated = childProps.aggregated
        field.dependent = true
        field.keys = childKeys
        if (field.gql == null) {
          field.gql = field.name.capitalize()
        }
        field.readOnly = ifNull(childProps.transitient, childProps.readOnly, child.type.readOnly) ?? false
        field.gqlCreate = `create${childProps.name.capitalize()}`
        field.childs = childKeys.toMapBy(
          (it) => it,
          (it) => ({ id: true, name: it, gql: 'String!' } as FormField<any>)
        )
        React.Children.forEach(childProps.children, loadFieldMap(field.childs))
      } else {
        if (childProps.subSelection) {
          field.subSelection = childProps.subSelection
        } else if (child.type.subSelection) {
          field.subSelection = child.type.subSelection
        }
        const depFields: any = callOrGet(child.type.fields, childProps, childProps.param)

        if (depFields) {
          Object.keys(depFields).forEach((depField) => {
            // eslint-disable-next-line no-prototype-builtins
            if (!depFields.hasOwnProperty(depField)) return
            const fieldValue = depFields[depField]
            if (typeof depField === 'number') {
              root[depField] = {
                ...field,
                name: depField,
                subSelection: fieldValue === true ? undefined : fieldValue
              }
            } else {
              loadFieldMap(root)(fieldValue)
            }
          })
        }
      }

      if (!child.type.skipGql && !childProps.skipGql) {
        root[fieldName] = field
      }
    }

  React.Children.forEach(children, loadFieldMap(fieldsMap))

  return fieldsMap
}
