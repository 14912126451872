import React from 'react'

export interface ZeroApiContextInfo {
  id: string
  name: string
  lang: string
  apiUrl: string
}

export const ZeroApiContext = React.createContext<ZeroApiContextInfo>({
  id: '',
  name: 'Context not set',
  lang: 'en',
  apiUrl: ''
})

export const useZeroApiContext = () => React.useContext(ZeroApiContext)
