import React from 'react'

import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material'

import { ReportFilter, Selection } from '../types'

interface FilterProps {
  reportFilter: ReportFilter
  filters?: Selection
  updateFilters: (filters: Selection) => void
}

export const ReportFilterView: React.FC<FilterProps> = ({ reportFilter, filters, updateFilters }) => {
  const options = reportFilter.options.map((it) => ({ id: it.id, label: it.title ?? 'n/a' }))
  const selection = filters?.[reportFilter.name]?.map((optionId) => options.find(({ id }) => id === optionId)) ?? []

  return (
    <Autocomplete
      limitTags={2}
      id={reportFilter.name}
      key={reportFilter.name}
      value={selection}
      options={options}
      sx={{ minWidth: 160, maxWidth: 320, flexWrap: 'nowrap' }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, autoCapitalize: 'off', autoComplete: 'off' }}
          autoComplete="off"
          variant="standard"
          label={reportFilter.title}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <FormControlLabel
            {...(props as any)}
            style={{ paddingTop: 0, paddingBottom: 0, marginRight: 0 }}
            value={option?.id}
            componentsProps={{ typography: { fontSize: 12, noWrap: true } }}
            control={<Checkbox size="small" checked={selected} />}
            label={option?.label}
          />
        )
      }}
      onChange={(_, values) => {
        return updateFilters({
          [reportFilter.name]: values.filterNotNull().map(({ id }) => id)
        })
      }}
      size="small"
      disablePortal
      selectOnFocus
      handleHomeEndKeys
      multiple
    />
  )
}
