import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet } from '../utils'

interface FormFieldProps extends GqlTabletField<any> {}

export const FilterField: GqlComponent<FormFieldProps, any> = (props: FormFieldProps) => {
  const item = props.item || props.rowData || {}
  let value = callOrGet(props.value || props.default)

  if (props.valueFromParent && props.parentRowData) {
    value = props.parentRowData[callOrGet(props.valueFromParent, props.parentRowData)]
  }

  if (item[props.name] !== value) {
    item[props.name] = value
  }

  return null
}

FilterField.gql = 'String'
