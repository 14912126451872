import { gql } from '@apollo/client'

import { extractArguments } from './extractArguments'
import { keyOnlyJson } from './keyOnlyJson'
import { Args, GqlQueryInput } from '../../types'
import { cleanJson } from '../../utils/cleanJson'

export const generateGql = (queries: { [key: string]: GqlQueryInput }, type: 'query' | 'mutation' = 'query') => {
  const queryNames = Object.keys(queries)
  if (!queryNames.length) throw Error('Got zero queries. ' + queries)

  const builder: string[] = []
  const args: Args = {}
  const queriesWithArgs = queryNames.map((name) => {
    const query = queries[name]
    return {
      name,
      args: extractArguments(query.args, args),
      select: query.select,
      alias: query.alias
    }
  })

  if (Object.keys(args).length !== 0) {
    builder.push(
      type +
        '(' +
        Object.keys(args)
          .map((arg) => arg + ': ' + args[arg])
          .join(', ') +
        ') {'
    )
  } else {
    builder.push(type + ' {')
  }

  queriesWithArgs.forEach((query) => {
    if (query.args === null) {
      console.warn('query has null args', query.name)
      return
    }

    const queryName = query.alias ? `${query.name}: ${query.alias}` : query.name
    if (Object.keys(query.args).length !== 0) {
      const argv = cleanJson(query.args)
        .replace(/"%%\$(.+?)%%"/gm, '$$$1')
        .replace(/"%@(.+?)@%"/gm, '$1')
      builder.push(`${queryName}(${argv.substring(1, argv.length - 2).trim()}) `)
    } else {
      builder.push(`${queryName} `)
    }
    if (query.select && Object.keys(query.select).length > 0) {
      builder.push(keyOnlyJson(query.select).trim())
    }
  })

  builder.push('}')

  try {
    return gql(builder.join('\n'))
  } catch (e) {
    console.warn(builder.join('\n'))
    throw e
  }
}
