import React from 'react'

import { Delete } from '@mui/icons-material'
import ResetIcon from '@mui/icons-material/RestartAlt'
import { Button, IconButton } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'

import { useTranslation } from '../../../../translations'

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1)
    },
    spacer: {
      flex: 1
    }
  })
)

interface Props {
  noDelete?: boolean
  onReset?: VoidFunction
  onSubmit?: VoidFunction
  onDelete?: VoidFunction
  onEdit?: VoidFunction
  status: {
    hasError: boolean,
    isCreated: boolean,
    isChanged: boolean,
  }
}

export const FormFooter: React.FC<Props> = ({ status, noDelete, onReset, onSubmit, onDelete }) => {
  const styles = useStyles()
  const translate = useTranslation()

  return (
    <div key="footer" className={styles.footer}>
      {noDelete !== true && (
        <IconButton key="button_delete" disabled={!status.isCreated} color="secondary" onClick={onDelete}>
          <Delete />
        </IconButton>
      )}
      <div className={styles.spacer} />
      <IconButton key="button_reset" disabled={!status.isChanged} color="secondary" onClick={onReset}>
        <ResetIcon />
      </IconButton>
      <Button key="button_save" disabled={status.hasError || !status.isChanged} onClick={onSubmit}>
        {translate('save')}
      </Button>
    </div>
  )
}
