import { useCallback, useMemo } from 'react'

import { MutationResult, useMutation, useQuery } from '@apollo/client'
import { MutationHookOptions } from '@apollo/client/react/types/types'

import { generateGql } from './generate-gql/generateGql'
import { Args, GqlQueryInput, GqlSelection, QueryOptions } from '../types'

export function useDynamicGqlQuery(method: string, args: Args, select: GqlSelection, options: QueryOptions = {}) {
  const query = useMemo(() => {
    return generateGql({
      [method]: { args, select }
    })
  }, [method, args, select])

  const { data, loading, error } = useQuery(query, {
    ...options,
    errorPolicy: 'ignore'
  })

  return { data: data && data[method], error, loading }
}

export function useDynamicGqlQueries(queries: { [key: string]: GqlQueryInput }, options = {}) {
  const query = useMemo(() => {
    return generateGql(queries)
  }, [queries])

  return useQuery(query, {
    ...options,
    errorPolicy: 'ignore'
  })
}

type GqlMutationResult = [(options?: any, variables?: any) => Promise<any>, MutationResult<any>]

export const useDynamicGqlMutation = (method: string, args: Args, select: GqlSelection = {}, options: QueryOptions = {}) => {
  // TODO: use apollo client
  const query = useMemo(() => {
    return generateGql({ [method]: { args, select } }, 'mutation')
  }, [method, args, select])

  const [call, { data, loading, error }] = useMutation(query, {
    ...options,
    errorPolicy: 'ignore'
  })

  const callMutation = useCallback((options: MutationHookOptions) => call(options).then(({ data }) => data[method]), [call, method])

  return [callMutation, { data: data && data[method], error, loading }] as GqlMutationResult
}
