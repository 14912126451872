import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IconName } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Autocomplete, CircularProgress, ListItem, ListItemText, TextField, Theme, Typography, TypographyTypeMap } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'

import { HiddenField } from './HiddenField'
import { useZeroApiContext } from '../context/ZeroApiContext'
import { useEntityRelationQuery } from '../gql/useEntityContextQuery'
import { useTranslation } from '../translations'
import { FormField, FormFieldChild, GqlComponent, GqlFilter, GqlTabletField, QueryOptions } from '../types'
import { callOrGet, ifNull, preventPropagation } from '../utils'

const useStyles = makeStyles((theme: Theme) => ({
  rootForm: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    padding: 0,
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available'
    },
    verticalAlign: '-webkit-baseline-middle'
  },
  root: {
    display: 'inline-grid',
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginBottom: 2,
    padding: 0,
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available'
    },
    verticalAlign: '-webkit-baseline-middle'
  }
}))

interface Option {
  id: string | null
  name: string
  label?: string
  icon?: string
}

const filterOptions = (options: Option[], params: { inputValue: string }) => {
  return options.filter((option) => option.name && option.name.cyrillicToLatin().indexOf(params.inputValue.cyrillicToLatin()) > -1)
}

export interface FormFieldChildProps extends FormField<string> {
  name: string
  isName?: boolean
}

interface FormFieldProps extends GqlTabletField<string> {
  children?: FormFieldChild<FormFieldChildProps, string>
  entity: string
  pk?: string
  keys?: string[]
  all?: boolean
  onLoad?: (data: any) => void
  exclude?: string[] | ((value: any, row: any, options: any[]) => string[])
  filterOptions?: string[] | ((value: any, row: any, options: any[]) => string[])
  onClear?: () => void
  byField?: string
  byFieldValue?: (row: any) => string | undefined
  nullLabel?: string
  nullIcon?: string
  icon?: boolean
  freeSolo?: any
  mapItem?: (item: any) => any
  gqlSearchMinChars?: number
  showAs?: 'input' | 'label'
  labelProps?: TypographyTypeMap['props']
}

interface EntityOptionsProps extends FormFieldProps {
  searchFilter?: GqlFilter | ((row: any, parentRow?: any) => GqlFilter | undefined)
}

const useEntityOptions = (props: EntityOptionsProps) => {
  const context = useZeroApiContext()
  const translate = useTranslation()

  const item = props.item || props.rowData
  const propsValue = props.name && props.value && typeof props.value === 'object' ? props.value[props.name] : props.value
  const value = (typeof propsValue === 'function' ? propsValue(item) : propsValue) ?? callOrGet(props.default, item, props.parentRowData)

  let hasName = false

  const fields: { name: string; gql: string; subSelection?: any }[] = [{ name: props.pk || 'id', gql: 'String!' }]

  const labels: FormField<any>[] = []
  React.Children.forEach(props.children, (child) => {
    if (!child || child.type !== HiddenField) return

    const childProps = child.props
    const gqlSelection = childProps.gqlSelection

    const subSelection: any[] | any | undefined = typeof gqlSelection === 'string' ? [gqlSelection] : gqlSelection

    if (!callOrGet(childProps.hidden, item)) {
      labels.push(childProps)
    }
    fields.push({
      name: childProps.name,
      gql: childProps.gql !== undefined ? childProps.gql : (child.type as any).gql,
      subSelection: Array.isArray(subSelection)
        ? subSelection.reduceRight((acc: any, a: string) => ({ [a]: acc }), true as any)
        : typeof subSelection === 'object'
        ? subSelection
        : undefined
    })

    if (childProps.name === 'name' || childProps.isName) hasName = true
  })

  if (!hasName) {
    fields.push({
      name: 'name',
      gql: 'Translation!',
      subSelection: { [context.lang]: true }
    })
  }

  if (props.icon) {
    fields.push({ name: 'icon', gql: 'String!' })
  }

  const propsFilter = useMemo(() => callOrGet(props.filter, item, props.parentRowData), [item, props.filter, props.parentRowData])

  const filter = useMemo(() => {
    if (propsFilter && props.searchFilter) {
      return {
        ...props.searchFilter,
        and: propsFilter
      }
    }

    if (propsFilter) return propsFilter
    if (props.searchFilter) return props.searchFilter

    return undefined
  }, [props.searchFilter, propsFilter])

  const queryOptions: QueryOptions = {
    filter,
    pollInterval: 30000,
    skip: callOrGet(props.hidden, item),
    onLoad: props.onLoad,
    all: props.all,
    keys: props.keys
  }

  const relValue = props.byFieldValue ? props.byFieldValue(item) : context.id

  const query = useEntityRelationQuery(props.entity, fields, props.byField, relValue, queryOptions)

  const { options, selected } = useMemo(() => {
    let selected: Option | null = null

    const exclude = callOrGet(props.exclude, value, item, query.items)

    let filteredItems = exclude ? query.items?.filter((item: any) => exclude.indexOf(item.id) === -1) : query.items

    if (props.filterOptions && filteredItems) {
      const include = callOrGet(props.filterOptions, value, item, filteredItems)
      filteredItems = filteredItems.filter((item: any) => include.indexOf(item.id) > -1)
    }

    const options =
      filteredItems?.map((item: any) => {
        const option = props.mapItem
          ? props.mapItem(item)
          : {
              ...item,
              name: item.name ? item.name[context.lang] : '',
              label: undefined
            }

        if (labels.length > 0) {
          const labelVals = labels.map((label) => {
            const labelVal = option[label.name]

            const gqlSelection = label.gqlSelection
            if (!gqlSelection) return labelVal
            if (typeof gqlSelection === 'string') return labelVal[gqlSelection]

            if (label.isName) {
              option.name = gqlSelection.reduce((acc, a) => acc[a], labelVal)
              return null
            }

            if (Array.isArray(gqlSelection)) {
              return gqlSelection.reduce((acc, a) => acc[a], labelVal)
            }

            return labelVal
          })
          option.label = labelVals.filter((it) => it !== null).join(', ')
        }

        if (option[props.pk || 'id'] === value) {
          selected = option
        }

        return option
      }) || []

    if (props.nullLabel && props.nullable) {
      const nullOption = {
        id: null,
        name: translate(props.nullLabel),
        icon: props.nullIcon
      }
      options.unshift(nullOption)
      if (!selected) {
        selected = nullOption
      }
    }

    return { options, selected }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.items, value])

  return { query, selected, value, options, labels }
}

export const EntityAutoComplete: GqlComponent<FormFieldProps, string> = (props: FormFieldProps) => {
  const classes = useStyles()
  const translate = useTranslation()

  const item = useMemo(() => props.item || props.rowData || {}, [props.item, props.rowData])

  const [isOpen, setIsOpen] = useState(false)
  const [createNew, setCreateNew] = useState<string>()
  const [searchFilter, setSearchFilter] = useState<GqlFilter>()

  const { query, selected, value, options } = useEntityOptions({
    ...props,
    searchFilter,
    hidden: props.gqlSearchMinChars ? !searchFilter : false
  })

  const propMetaName = useMemo(() => (props.name.endsWith('Id') ? props.name.replace(/Id$/, '') : `_${props.name}`), [props.name])

  const onChange = useCallback(
    (_event: any | undefined, value: any) => {
      if (!props.onChange) return
      if (value == null) {
        return props.onChange(null)
      }
      if (typeof value === 'object') {
        return props.onChange(value[props.pk || 'id'], { [propMetaName]: value })
      }
      if (props.freeSolo) {
        setCreateNew(value)
      }
    },
    [propMetaName, props]
  )

  const onNewItem = (data: any | undefined) => {
    if (!data) {
      setCreateNew(undefined)
      return
    }
    query.refresh().then(() => {
      if (props.onChange) {
        props.onChange(data[props.pk || 'id'])
      }
      setCreateNew(undefined)
    })
  }

  useEffect(() => {
    if (selected === null && props.auto && options.length > 0) {
      if (props.nullable) {
        item[props.name] = null
      } else {
        setTimeout(() => {
          onChange(undefined, options[0])
        }, 10)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, options.length])

  useMemo(() => {
    if (propMetaName && item && !item[propMetaName] && selected) {
      if (props.isForm) {
        onChange(undefined, selected)
      } else {
        item[propMetaName] = selected
      }
    }
  }, [item, onChange, propMetaName, props.isForm, selected])

  const fieldSize = props.size === 'dense' ? 'small' : 'medium'
  const fontSize = props.fontSize ?? (props.size !== 'normal' ? 14 : 16)

  const onClear = () => {
    if (props.nullable) {
      item[props.name] = null
    }

    if (props.onClear) props.onClear()
  }

  const onInputChange = (_e: React.SyntheticEvent<Element, Event>, value: string) => {
    if (value.length === 0) {
      return onClear()
    }
    if (props.gqlSearchMinChars && props.gqlSearchMinChars <= value.length) {
      setSearchFilter({ by: 'intName', like: `${value.cyrillicToLatin()}%` })
    }
  }

  if (props.showAs === 'label') {
    return (
      <Typography variant="body1" {...props.labelProps} align={props.align}>
        {selected?.name}
      </Typography>
    )
  }

  return (
    <React.Fragment>
      <Autocomplete
        freeSolo={props.freeSolo !== undefined}
        className={props.isForm ? classes.rootForm : classes.root}
        style={{ flexGrow: props.fullWidth || props.flexGrow ? 1 : 0 }}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        autoHighlight
        selectOnFocus
        fullWidth={props.fullWidth}
        handleHomeEndKeys
        options={options}
        filterOptions={filterOptions}
        value={selected}
        onChange={onChange}
        onInputChange={onInputChange}
        onSubmit={props.onSubmit}
        disabled={callOrGet(props.disabled, value)}
        size={fieldSize}
        onKeyDown={(event) => preventPropagation(event, isOpen)}
        isOptionEqualToValue={(option, value) => value && option.id === value.id}
        getOptionLabel={(option) => (typeof option !== 'string' ? option?.name || '' : '')}
        renderOption={
          props.icon
            ? (props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <FontAwesomeIcon icon={option.icon as IconName} color="#254" size="sm" style={{ marginRight: 4 }} />
                  {option.name}
                </Box>
              )
            : (props, option) => (
                <ListItem {...props} dense>
                  <ListItemText primary={option.name} secondary={option.label} secondaryTypographyProps={{ style: { fontSize: 12 } }} />
                </ListItem>
              )
        }
        renderInput={(params) => {
          params.inputProps.autoCorrect = 'off'
          params.inputProps.autoCapitalize = 'off'
          params.inputProps.style = { fontSize }
          return (
            <TextField
              {...params}
              label={
                props.isForm
                  ? React.isValidElement(props.label)
                    ? props.label
                    : translate(props.label?.toString() || props.name.snakeCase())
                  : undefined
              }
              error={callOrGet(props.error, value)}
              variant={props.isForm ? 'outlined' : 'standard'}
              size={fieldSize}
              fullWidth={props.fullWidth}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {query.isLoading ? (
                      <CircularProgress
                        color="inherit"
                        size={props.size !== 'normal' ? 12 : 18}
                        style={props.isForm ? { marginLeft: 4 } : {}}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )
        }}
      />
      {createNew && props.freeSolo && props.freeSolo({ value: createNew, onClose: onNewItem })}
    </React.Fragment>
  )
}

EntityAutoComplete.gql = 'String'

const Render = (props: FormFieldProps) => {
  const translate = useTranslation()
  const { query, selected, labels } = useEntityOptions(props)

  if (query.isLoading) {
    return <CircularProgress color="inherit" size={props.size === 'dense' ? 14 : 18} style={props.isForm ? { marginLeft: 4 } : {}} />
  }

  const selectedTitle = selected && selected.name

  if (props.rowData[`_${props.name}`] !== selectedTitle) {
    props.rowData[`_${props.name}`] = selectedTitle
  }

  if (selected) {
    if (labels.length > 0) {
      return (
        <div style={{ display: 'inline-block' }}>
          {props.icon && <FontAwesomeIcon icon={selected.icon as IconName} color="#265" style={{ marginRight: 4 }} />}
          {selectedTitle}
          <span style={{ fontSize: 12, color: '#666', paddingLeft: 4 }}>({selected.label})</span>
        </div>
      )
    }
    return (
      <div style={{ display: 'inline-block' }}>
        {props.icon && <FontAwesomeIcon icon={selected.icon as IconName} color="#265" style={{ marginRight: 4 }} />}
        {selectedTitle}
      </div>
    )
  }

  if (props.nullLabel && props.nullable) {
    return (
      <div style={{ display: 'inline-block' }}>
        {props.icon && props.nullIcon && <FontAwesomeIcon icon={props.nullIcon as IconName} color="#265" style={{ marginRight: 4 }} />}
        {translate(props.nullLabel)}
      </div>
    )
  }
  return <div style={{ display: 'inline-block' }} />
}

EntityAutoComplete.render = (props, value, row) => {
  return <Render {...props} value={value} rowData={row} />
}

EntityAutoComplete.filter = (props, filter, _value, row) => {
  return row[`_${props.name}`]?.cyrillicToLatin()?.indexOf(filter.cyrillicToLatin()) > -1
}
