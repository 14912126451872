import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Divider, Stack, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

interface Props {
  children: React.ReactElement[]
  title: string
  icon?: React.ReactNode
  defaultExpanded?: boolean
}

export const ReportControls: React.FC<Props> = ({ children, title, icon, defaultExpanded }) => {
  return (
    <Accordion defaultExpanded={defaultExpanded} TransitionProps={{ unmountOnExit: true }} disableGutters square>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ height: 40 }} aria-controls="panel1a-content" id="panel1a-header">
        {icon || false}
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: 1 }}>
        <Divider />
        <Stack direction="row" spacing={1} sx={{ pt: 1 }} flexWrap="wrap">
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
