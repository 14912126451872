import { Args } from '../../types'

export const extractArguments: (args: Args, outArgs: Args) => Args | null = (args: Args | undefined, outArgs: Args) => {
  if (args === null || args === undefined) return null
  if (args instanceof Array) {
    return args.map((arg) => extractArguments(arg, outArgs))
  }
  if (typeof args !== 'object') {
    return args
  }

  const newArgs: { [key: string]: any } = {}
  for (const key in args) {
    const value = args[key]
    if (key.startsWith('$')) {
      if (value?.alias) {
        outArgs[value.alias] = value.type
        newArgs[key.substring(1)] = `%%${value.alias}%%`
      } else {
        outArgs[key] = value
        newArgs[key.substring(1)] = `%%${key}%%`
      }
    } else if (key.endsWith('$')) {
      newArgs[key.substring(0, key.length - 1)] = `%@${value}@%`
    } else if (key.indexOf('$') > -1) {
      const [prefix, name] = key.split('$')
      newArgs[name] = `%%$${prefix + name}%%`
    } else {
      newArgs[key] = extractArguments(value, outArgs)
    }
  }

  return newArgs
}
