/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { CSSProperties, useMemo, useState } from 'react'

import MaterialTable, {
  Action,
  Column,
  DetailPanel,
  MTableBodyRow,
  MTableCell,
  MTableEditRow,
  MTableHeader,
  MTableToolbar,
  Options
} from '@material-table/core'
import { ArrowBack } from '@mui/icons-material'
import BoltIcon from '@mui/icons-material/Bolt'
import { IconButton, Paper, TableCell, TableHead, TableRow, Theme, Typography, alpha } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useInRouterContext, useNavigate } from 'react-router'

import { MaterialTableGroupRowSmall } from './MaterialTableGroupRowSmall'
import { TABLE_ICONS } from './TableIcons'
import { DEFAULT_EXPORT_OPTIONS, DenseTypes, FontSizes, HeaderFontSizes, HeaderGroupDenseTypes } from '../../constants'
import { useTranslation } from '../../translations'
import { callOrGet } from '../../utils'

const useStyles = makeStyles((_theme: Theme) => ({
  fixToolbarNoTitlePadding: {
    '& :first-child': {
      paddingLeft: 0
    }
  },
  fixToolbarWithBack: {
    '& * span > div': { height: '100%' },
    '& > div': { paddingLeft: 12 },
    '& * div > div': { width: '100%' }
  },
  fixToolbar: {
    '& * span > div': { height: '100%' },
    '& * div > div': { width: '100%' }
  },
  tableRow: {
    '& .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root': {
      width: 32,
      height: 32
    }
  },
  tableRowDetailed: {
    '& * > .css-mf1cb5-MuiButtonBase-root-MuiIconButton-root': {
      width: 32,
      height: 32,
      alpha: 0.5
    },
    '&:hover': { backgroundColor: '#d3e9df !important' }
  },
  headerRow: {
    lineHeight: 1.2,
    position: 'sticky',
    whiteSpace: 'nowrap',
    padding: 0
  },
  header: {
    lineHeight: 1.2,
    position: 'sticky',
    whiteSpace: 'nowrap',
    padding: 4
  }
}))

export interface GqlTableColumn<T extends object> extends Column<T> {
  fieldName: string
  groupName?: string
  colSpan?: number | ((rowData: T, value: any) => number)
  summary?: (
    data: any[],
    currentData?: any[],
    index?: number,
    noFormat?: boolean
  ) => { value?: React.ReactNode; style?: React.CSSProperties } | React.ReactNode
}

export interface ExportOption<T extends object> {
  label: string
  exportFunc: (
    columns: Column<T>[],
    renderData: T[],
    tableData: {
      searchedData: T[]
      filteredData: T[]
      groupedData: T[]
    }
  ) => void
}

export interface PlainTableProps<T extends object> {
  lang?: string
  deps?: any[]
  items: T[]
  columns: GqlTableColumn<T>[]
  detailPanel?: DetailPanel<any>[]
  isLoading?: boolean
  options?: Options<any>
  headerGroupStyles?: { [key: string]: CSSProperties }
  noAutoWidth?: boolean

  onItemClick?: (e?: React.MouseEvent, row?: T) => void
  onRowAdd?: (data: T) => Promise<any>
  onRowUpdate?: (data: T) => Promise<any>
  onRowDelete?: (data: T) => Promise<any>
  onBulkUpdate?: (changes: Record<number, { oldData: T; newData: T }>) => Promise<any>
  refresh?: (data: T) => Promise<any> | boolean | undefined | void
  dense?: keyof typeof DenseTypes
  fontSize?: keyof typeof FontSizes
  grouping?: boolean
  search?: string
  elevation?: number
  noTitlePadding?: boolean
  noSearch?: boolean
  noPaging?: boolean
  noTitle?: boolean
  noExport?: boolean
  exportOptions?: ExportOption<T>[]
  title?: string | React.ReactElement<any>
  parentTitle?: string | React.ReactElement<any>
  fixedLeft?: number
  fixedRight?: number
  filterRows?: (rows: T[]) => T[]
  style?: React.CSSProperties
  hasBack?: boolean
  onItems?: (items: any[]) => void
  onSelectionChange?: (data: any[], rowData?: any) => void
  onLoad?: () => void
  canDelete?: (row: any) => boolean
  treeItemsLookup?: (row: T, rows: T[]) => T
  titleActions?: React.ReactNode
  actions?: (Action<T> | ((T: T) => Action<T>) | { action: (T: T) => Action<T>; position: string })[]
  pageSize?: number
  pageSizeOptions?: number[]
  emptyRows?: boolean
}

export interface HeaderColumn {
  name: string
  title: string | JSX.Element
  align?: 'left' | 'right' | 'center'
  width?: number | string
  span?: number
  actions?: boolean
  hidden?: boolean
  columns?: HeaderColumn[]
}

const BackButton: React.FC = () => {
  const navigationAvailable = useState(useInRouterContext())[0]
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = navigationAvailable ? useNavigate() : () => console.error('useNavigate not in <Router />')
  return (
    <IconButton onClick={() => navigate('./..')}>
      <ArrowBack color="primary" />
    </IconButton>
  )
}

const MTableEditRowDeepCopy: React.FC = (props: any) => {
  const data = useMemo(() => {
    if (!props.data) return props.data
    const data = JSON.parse(JSON.stringify(props.data))
    return { ...data, __originalRowData: props.data }
  }, [props.data])

  return <MTableEditRow {...props} data={data} />
}

function getTitle(props: PlainTableProps<any>, fullTitle: JSX.Element | string | undefined) {
  if (props.titleActions || props.hasBack) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.hasBack && <BackButton />}
        <Typography variant="h6">{fullTitle}</Typography>
        {props.titleActions ?? null}
      </div>
    )
  }

  return fullTitle
}

export function PlainTable<T extends object>(props: PlainTableProps<T>) {
  const classes = useStyles()
  const translate = useTranslation()

  const items = props.items || []

  const treeItemsLookup = props.treeItemsLookup

  const detailPanel: DetailPanel<any>[] = props.detailPanel || []

  const fontSize = props.fontSize ? FontSizes[props.fontSize] ?? 12 : 12

  const paddings = HeaderGroupDenseTypes[props.dense ?? 'normal']
  const headerFontSize = HeaderFontSizes[props.fontSize ?? 'normal']

  const headerStyle: React.CSSProperties = {
    fontSize: headerFontSize,
    lineHeight: 1,
    position: 'sticky',
    whiteSpace: 'nowrap',
    paddingLeft: paddings[0],
    paddingTop: paddings[1],
    paddingRight: paddings[2],
    paddingBottom: paddings[3]
  }

  const headerGroups: HeaderColumn[] = []
  if (props.onRowUpdate || props.onRowDelete) {
    headerGroups.push({
      name: 'actions',
      actions: true,
      span: 1,
      align: 'center',
      title: <BoltIcon color="action" sx={{ opacity: 0.5 }} />
    })
  }
  let headerRows = 1

  const totalWidth = props.columns.sumOf((col) => (col.hidden ? 0 : typeof col.width === 'number' ? (col.width as number) : 12))

  const columns = props.columns.map((column) => {
    const { groupName, title, hidden, width } = column

    if (hidden) return { ...column }

    const columnTitle = (title as string) || ' '
    if (groupName) {
      const group = headerGroups[headerGroups.length - 1]
      if (group && group.name === groupName) {
        if (group.columns) {
          group.columns.push({ name: columnTitle, title: columnTitle, hidden, width })
        } else {
          group.columns = [{ name: columnTitle, title: columnTitle, hidden, width }]
        }
      } else {
        headerGroups.push({
          name: groupName,
          title: translate(groupName),
          columns: [{ name: columnTitle, title: columnTitle, hidden, width }]
        })
      }
      headerRows = 2
    } else {
      headerGroups.push({ name: columnTitle, title: columnTitle, hidden, width })
    }
    if (!props.noAutoWidth) {
      const width = typeof column.width !== 'number' ? 12 : column.width < 1 ? 12 : column.width
      const columnWidth = Math.floor((width * 100) / totalWidth) + '%'
      return { ...column, width: columnWidth, headerStyle: { ...column.headerStyle, width: columnWidth } } as Column<T>
    }
    return column as Column<T>
  })

  const hasDetails = detailPanel.length > 0

  if (hasDetails || treeItemsLookup) {
    headerGroups.unshift({ name: 'details', title: '', width: 5, actions: true })
  }

  const titleProp = props.title
  const titleContent = typeof titleProp === 'string' ? translate(titleProp) : titleProp

  const parentTitle = typeof props.parentTitle === 'string' ? translate(props.parentTitle) : undefined

  const fullTitle = parentTitle ? (
    <React.Fragment>
      <span style={{ color: '#888' }}>{parentTitle}</span> / {titleContent}
    </React.Fragment>
  ) : (
    titleContent
  )

  const title = getTitle(props, fullTitle)

  const headerGroupStyles = props.headerGroupStyles || {}

  return (
    // @ts-ignore
    <MaterialTable
      style={props.style}
      onRowClick={props.onItemClick}
      editable={{
        isDeletable: props.canDelete,
        onRowAdd: props.onRowAdd,
        onRowUpdate: props.onRowUpdate,
        onRowDelete: props.onRowDelete,
        onBulkUpdate: props.onBulkUpdate
      }}
      actions={props.actions}
      icons={TABLE_ICONS}
      title={title}
      isLoading={props.isLoading}
      columns={columns}
      data={props.filterRows ? props.filterRows(items) : items}
      renderSummaryRow={({ data, index, column, currentData }) => (column as GqlTableColumn<T>).summary?.(data, currentData, index)}
      detailPanel={hasDetails ? detailPanel : undefined}
      onSelectionChange={props.onSelectionChange}
      options={{
        ...props.options,
        selection: Boolean(props.onSelectionChange),
        emptyRowsWhenPaging: props.emptyRows,
        search: !props.noSearch,
        searchText: props.search,
        paging: !props.noPaging,
        grouping: props.grouping,
        exportMenu: props.noExport ? [] : props.exportOptions ?? DEFAULT_EXPORT_OPTIONS ?? [],
        fixedColumns: {
          left: props.fixedLeft,
          right: props.fixedRight
        },
        pageSize: props.pageSize ?? 20,
        pageSizeOptions: props.pageSizeOptions ?? [20, 50, 100, 200],
        padding: props.dense === 'dense' ? 'dense' : 'default',
        rowStyle: (data, _index, level) => {
          const isExpanded = (data as any).tableData?.showDetailPanel || false
          return {
            fontSize,
            backgroundColor: level > 0 ? alpha('#089', level * 0.15) : undefined,
            boxShadow: isExpanded ? '0px 0px 6px' : undefined,
            background: detailPanel.length ? (isExpanded ? '#f8fbf9' : '#eaf1f1') : undefined,
            transition: 'boxShadow 0.3s ease-out, background 0.3s ease-out',
            fontWeight: isExpanded ? 'bold' : undefined
          }
        },
        actionsCellStyle: {
          fontSize: 6,
          padding: 0
          // width: "5%",
        }
      }}
      components={{
        Container: props.elevation
          ? ({ style, children }) => (
              <Paper elevation={props.elevation} style={style}>
                {children}
              </Paper>
            )
          : Paper,
        Header:
          headerRows && headerRows > 1
            ? (headerProps) => {
                let columnIdx = 0
                let columnIdx2 = 0
                return (
                  <TableHead {...headerProps}>
                    <TableRow className={classes.headerRow}>
                      {headerGroups.map((headerGroup, index) => {
                        const columns = headerGroup.columns
                        if (columns === undefined) {
                          const columnProps = headerGroup.actions ? {} : headerProps.columns[columnIdx++] || {}
                          const style = { ...headerStyle, ...columnProps.headerStyle }
                          if (headerGroup.width) {
                            style.width = headerGroup.width
                          } else if (columnProps.width) {
                            style.width = columnProps.width
                          }

                          return (
                            <TableCell
                              style={style}
                              rowSpan={2}
                              colSpan={headerGroup.span || 1}
                              hidden={headerGroup.hidden}
                              align={columnProps.align || headerGroup.align || 'left'}
                            >
                              {headerGroup.title}
                            </TableCell>
                          )
                        } else {
                          columnIdx += columns.length
                          const newHeaderGroup = headerGroups[index + 1]
                          const isNextGroup = newHeaderGroup && newHeaderGroup.columns !== undefined

                          return (
                            <TableCell
                              style={{
                                ...headerStyle,
                                ...headerGroupStyles[headerGroup.name],
                                borderRight: isNextGroup ? '1px solid rgba(224, 224, 224, 1)' : undefined
                              }}
                              colSpan={columns.length}
                              align="center"
                              hidden={headerGroup.hidden}
                            >
                              {headerGroup.title}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                    <TableRow className={classes.headerRow}>
                      {headerGroups.flatMap((headerGroup) => {
                        if (headerGroup.actions) return []
                        const columns = headerGroup.columns
                        if (columns === undefined) {
                          columnIdx2++
                          return []
                        }
                        return columns.map((column) => {
                          const columnProps = headerProps.columns[columnIdx2++] || {}

                          return (
                            <TableCell
                              style={{ ...headerStyle, ...columnProps.headerStyle }}
                              align={columnProps.align || 'center'}
                              hidden={headerGroup.hidden}
                            >
                              {column.title}
                            </TableCell>
                          )
                        })
                      })}
                    </TableRow>
                  </TableHead>
                )
              }
            : (headerProps) => <MTableHeader {...headerProps} />,
        Toolbar: (toolbarProps) => {
          if (props.noTitle) return <span />
          if (props.noTitlePadding) {
            return (
              <div className={classes.fixToolbarNoTitlePadding}>
                <MTableToolbar {...toolbarProps} />
              </div>
            )
          }
          return (
            <div className={props.hasBack ? classes.fixToolbarWithBack : classes.fixToolbar}>
              <MTableToolbar {...toolbarProps} />
            </div>
          )
        },
        Row: (props) => <MTableBodyRow {...props} className={hasDetails ? classes.tableRowDetailed : classes.tableRow} />,
        EditRow: MTableEditRowDeepCopy,
        GroupRow: props.dense ? (props) => <MaterialTableGroupRowSmall {...props} /> : undefined,
        Cell: (props) => {
          if (props.columnDef.colSpan === undefined) return <MTableCell {...props} />
          const span = callOrGet(props.columnDef.colSpan, props.rowData, props.value)
          if (span === 0) return null
          return <MTableCell colSpan={span} {...props} />
        }
      }}
      parentChildData={treeItemsLookup}
      localization={{
        header: {
          actions: <BoltIcon color="action" sx={{ opacity: 0.5 }} /> // translate("actions"),
        },
        body: {
          emptyDataSourceMessage: <span style={{ color: '#777' }}>{translate('no_records_display')}</span>,
          addTooltip: translate('add'),
          deleteTooltip: translate('delete'),
          editTooltip: translate('edit'),
          editRow: {
            deleteText: translate('delete_text_row'),
            cancelTooltip: translate('cancel'),
            saveTooltip: translate('save')
          }
        },
        toolbar: {
          exportCSVName: translate('export_csv_name'),
          exportPDFName: translate('export_pdf_name'),
          exportTitle: translate('export'),
          searchAriaLabel: translate('search'),
          searchTooltip: translate('search'),
          searchPlaceholder: translate('search')
        },
        pagination: {
          firstTooltip: translate('first_page'),
          lastTooltip: translate('last_page'),
          previousTooltip: translate('previous_page'),
          nextTooltip: translate('next_page')
        }
      }}
    />
  )
}

export const PlainTableMemo: React.FC<PlainTableProps<any>> = React.memo(
  (props: PlainTableProps<any>) => {
    return <PlainTable {...props} />
  },
  (prev, next) => {
    return prev.items === next.items && prev.columns === next.columns
  }
)
