import React, { FormEvent, MutableRefObject, ReactElement, useCallback, useMemo } from 'react'

import { createStyles, makeStyles } from '@mui/styles'

import { FormFooter } from './controls'
import { FormLoading, GqlFormProgressBarProps } from './controls/loading'
import { getFormData } from './getFormData'
import { DenseTypes, FontSizes } from '../../constants'
import { useZeroApiContext } from '../../context'
import { HiddenField } from '../../fields'
import { useEntityGql, useProcessFields } from '../../gql-fields'
import { useTranslation } from '../../translations'
import { Field, FormField, FormQueryProps, GqlComponent, GqlFilter, GqlMutation, GqlQuery, ItemData } from '../../types'
import { callOrGet, ifNull, setNestedValue, useResetableState } from '../../utils'

const getFieldsData: (fields: Field<any>[], contextData?: any) => { [p: string]: any } = (fields, contextData) => {
  return fields.toMapBy(
    (field) => field.name,
    (field) => {
      if (field.childs) {
        return getFieldsData(Object.values(field.childs).filterNotNull(), contextData?.[field.name])
      } else {
        const data = contextData?.[field.name]
        if (data) return data
        return ifNull(callOrGet(field.value), callOrGet(field.default))
      }
    }
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center'
    }
  })
)

type FormChild = ReactElement<FormField<any>, GqlComponent<FormField<any>>>

interface GqlFormProps extends FormQueryProps, GqlFormProgressBarProps {
  style?: React.CSSProperties
  rowData?: any
  items?: any[]
  footer?:
    | JSX.Element
    | ((
        item: any,
        status: any,
        actions: { onReset: VoidFunction; onSubmit: VoidFunction; onEdit: (data: any) => void; onDelete: VoidFunction }
      ) => JSX.Element)
    | undefined

  onSubmit?: (data: ItemData) => Promise<any> | boolean | undefined | void
  onSave?: (data: ItemData, isNew: boolean) => Promise<any> | boolean | undefined | void
  onDelete?: (data: ItemData) => Promise<any> | boolean | undefined | void
  refresh?: (data: ItemData) => Promise<any> | boolean | undefined | void
  onLoad?: (data?: any, methodName?: string) => void
  onChange?: (data: any) => void

  entity: string

  loading?: (isLoading: boolean) => JSX.Element
  keys?: string[]
  dense?: keyof typeof DenseTypes
  fontSize?: keyof typeof FontSizes
  content?: JSX.Element | JSX.Element[]
  className?: string

  customQuery?: string
  entityRelFieldName?: string
  entityRelFieldValue?: string

  dateRangeFilter?: {
    startDate: Date
    endDate: Date
  }

  args?: { [key: string]: any }
  filter?: GqlFilter
  queryRef?: MutableRefObject<GqlQuery<any> | GqlMutation<any> | undefined>
  autoRefresh?: boolean
  noDelete?: boolean
  all?: boolean
}

const hasInputChanges = (fields: (Field<any> | undefined)[], item: any, input: any) => {
  if (item == null) return input != null

  return fields.any((field) => {
    if (!field) return false
    const itemData = item ? item[field.name] : undefined
    const inputData = input ? input[field.name] : undefined

    if (inputData == null) return false
    if (itemData == null) return true

    if (field.list) {
      if (!Array.isArray(inputData)) return false
      if (field.childs == null) return false
      const childs = Object.values(field.childs)
      return inputData.any((it, index) => hasInputChanges(childs, itemData[index], it))
    } else if (field.nested) {
      if (field.childs == null) return false
      return hasInputChanges(Object.values(field.childs), itemData, inputData)
    } else {
      return inputData.toString() !== itemData.toString()
    }
  })
}

export const GqlForm = (props: GqlFormProps) => {
  const classes = useStyles()
  const translate = useTranslation()

  const { fields, fieldsMap, query, mutation, updateRelValue } = useEntityGql({ ...props })

  const { fieldDeaggregators, fieldValueGetters, groupByColumns, fieldAggregators } = useProcessFields(props, fieldsMap)

  if (props.queryRef) {
    props.queryRef.current = query
  }

  const context = useZeroApiContext()

  const { entityRelFieldName: relField, entityRelFieldValue: relValue } = props

  const contextData = useMemo(() => {
    const field = relField !== undefined ? relField : `${context.name.toLowerCase()}Id`
    const value = relField !== undefined ? relValue : context.id

    return { [field]: value }
  }, [context.id, context.name, relField, relValue])

  const dataItem = query.item instanceof Array ? query.item[0] : query.item

  const data = useMemo(() => {
    const itemData = dataItem || props.item || getFieldsData(fields, contextData) || {}

    if (!fieldAggregators.length) {
      return JSON.parse(JSON.stringify(itemData))
    }

    const aggregatedItems = fields.toMapBy(
      (field) => field.name,
      (field) => {
        const value = itemData[field.name]
        if (!field.aggregated) return value

        if (!Array.isArray(value) || !value.length) return []

        const fieldValueGettersMap = fieldValueGetters.toMapBy(
          (it) => it.fieldPathName,
          (it) => it.getValue
        )

        const agrItems = value.groupBy((item) => {
          return groupByColumns.joinOf('|', (column) => fieldValueGettersMap[column](item))
        })

        const result = Object.values(agrItems).map((items) => {
          const agrItem = { ...items[0], _agrItems: items }
          fieldAggregators.forEach(([column, aggregator]) => {
            setNestedValue(agrItem, column, aggregator(items))
          })
          return agrItem
        })

        return result
      }
    )

    return aggregatedItems
  }, [contextData, dataItem, fieldAggregators, fieldValueGetters, fields, groupByColumns, props.item])

  const [inputData, setInputData] = useResetableState<any>(undefined, [data])

  const itemData = useMemo(() => ({ ...data, ...inputData }), [data, inputData])

  const onChange = useCallback(
    (name: string) => (value: FormEvent<HTMLElement>, itemUpdates?: any) => {
      if (query.isLoading) return
      setInputData((item) => {
        return { ...item, ...itemUpdates, [name]: value, _orgState: item?._orgState ?? data }
      })
    },
    [data, query.isLoading, setInputData]
  )

  const doSubmit = useCallback(
    (data: any) => {
      const propsResult = props?.onSubmit && props.onSubmit(data)
      if (propsResult instanceof Promise) return propsResult
      if (propsResult) return undefined

      if (!fieldDeaggregators.length) {
        if (dataItem) {
          return mutation?.updateItem && mutation.updateItem(dataItem, data, false)
        } else {
          return mutation?.saveItem && mutation.saveItem(data, false)
        }
      }

      // const enhanceItemValues = createItemValuesEnhancer(props, fieldsMap, fieldValueGetters)

      // const newItem = enhanceItemValues(data)

      return mutation?.deepEdit(fieldDeaggregators, dataItem, data, false)
    },
    [props, fieldDeaggregators, mutation, dataItem]
  )

  const onSubmit = useCallback(async () => {
    const data = getFormData(fields, itemData)

    const result = await doSubmit(data)

    const resultData = { ...data, ...result }
    if (props.onSave) await props.onSave(resultData, dataItem == null)
    if (props.refresh) await props.refresh(resultData)
    if (props.entityRelFieldName) {
      updateRelValue?.(resultData[props.entityRelFieldName])
    }

    await query.refresh()
    setInputData(undefined)
  }, [dataItem, doSubmit, fields, itemData, props, query, setInputData, updateRelValue])

  const onDelete = useCallback(() => {
    mutation?.deleteItem(itemData).then(() => {
      if (props.onDelete) props.onDelete(itemData)
      if (props.refresh) props.refresh(itemData)
    })
  }, [itemData, mutation, props])

  const onEdit = (data: any) => {
    setInputData(data)
  }

  const fontSize = props.fontSize ? FontSizes[props.fontSize] ?? 12 : 12

  const isLoading = query.isLoading || mutation?.isLoadingAction || false

  const { children, hasError } = useMemo(() => {
    let hasError = false

    const children = React.Children.map(props.children, (child: FormChild) => {
      if (!React.isValidElement(child)) return child
      if (child.type === HiddenField) return null

      const childProps = child.props
      const fieldName = childProps.name
      if (fieldName === undefined) return child

      const field = fieldsMap[fieldName]
      if (field === undefined) {
        return child
      }

      const value =
        typeof field.value === 'function'
          ? field.value(itemData) ?? callOrGet(field.default, itemData)
          : itemData[fieldName] ?? field.value ?? callOrGet(field.default, itemData)

      if (inputData && value !== itemData[fieldName]) {
        if (!inputData._orgState) {
          inputData._orgState = data
        }
        inputData[fieldName] = value
      }

      if (callOrGet(childProps.hidden, value, itemData) === true) return null

      const error =
        (childProps.nullable !== true && !child.type.readOnly && (value === undefined || value?.length === 0)) ||
        callOrGet(child.type.validate, childProps, value)

      if (error) hasError = true

      return React.cloneElement<FormField<any>>(child, {
        isForm: true,
        key: childProps.key || fieldName,
        label:
          typeof childProps.label === 'string'
            ? translate(childProps.label ?? fieldName.snakeCase().replace(/_id$/g, '') ?? '')
            : childProps.label,
        value,
        hidden: false,
        item: itemData,
        disabled: childProps.disabled || isLoading,
        isLoading,
        error,
        size: props.dense !== 'dense' ? 'normal' : 'dense',
        fontSize,
        onChange: onChange(fieldName),
        onSubmit,
        style: {
          flexGrow: 1
        },
        parentEntity: props.entity,
        parentRowData: props.rowData
      })
    })

    return { children, hasError }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsMap,
    fontSize,
    inputData,
    isLoading,
    itemData,
    onChange,
    onSubmit,
    props.children,
    props.dense,
    props.entity,
    props.rowData,
    translate
  ])

  const isItemCreated = (props.item || query.item) !== undefined

  if (props.content) {
    children.push(callOrGet(props.content, itemData, isItemCreated))
  }

  const formStatus = useMemo(
    () => ({
      hasError: hasError && false,
      isCreated: isItemCreated,
      isChanged: inputData != null && hasInputChanges(fields, data, inputData)
    }),
    [fields, hasError, inputData, isItemCreated, data]
  )

  const onReset = useCallback(() => {
    setInputData(undefined)
  }, [])

  const footer = props.footer
  if (footer instanceof Function) {
    children.push(footer(itemData, formStatus, { onReset, onSubmit, onDelete, onEdit }))
  } else if (footer) {
    children.push(footer)
  } else {
    children.push(<FormFooter status={formStatus} noDelete={props.noDelete} onReset={onReset} onSubmit={onSubmit} onDelete={onDelete} />)
  }

  const loading = props.loading
  if (loading instanceof Function) {
    children.push(loading(isLoading))
  } else if (loading) {
    children.push(loading)
  } else {
    children.push(<FormLoading loadingVariant={props.loadingVariant} loadingBar={props.loadingBar} isLoading={isLoading} />)
  }

  const rootClasses = [classes.root]

  if (props.className) rootClasses.push(props.className)

  return (
    <form className={rootClasses.join(' ')} noValidate autoComplete="off">
      {children}
    </form>
  )
}
