import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'

import { generateGql } from './generate-gql/generateGql'
import { selectionFromFields } from './selection-from-fields/selectionFromFields'
import { useZeroApiContext } from '../context/ZeroApiContext'
import { Field, GqlFilter, GqlQuery, QueryOptions } from '../types'
import useLazyEffect from '../utils/useLazyEffect'

export const useEntityQuery = <T>(entity: string, fields: Field<any>[], relField: string, relValue: any, options: QueryOptions = {}) => {
  const context = useZeroApiContext()
  const isPageVisible = true // usePageVisibility()
  const allFields = fields || []
  const gqlFields = allFields.filter((field) => field.gql !== undefined && !field.derived)

  const getItemsMethodName = useMemo(() => {
    if (options.all) return `getAll${entity}s`
    if (relField === `${context.name.toLowerCase()}Id`) return `get${entity}sByContext${context.name.capitalize()}Id`
    if (options.single) return `get${entity}By${relField.capitalize()}`
    return `get${entity}sBy${relField.capitalize()}`
  }, [context.name, entity, options.all, options.single, relField])

  const gqlGetList = useMemo(() => {
    if (gqlFields.length === 0) throw Error('No gql fields for ' + entity)

    let filter: GqlFilter | undefined

    const addFilter = (filterOptions: GqlFilter) => {
      if (filter) {
        filterOptions.and = filter
      }
      filter = filterOptions
    }
    if (options.dateRangeFilter) {
      addFilter({
        by: options.dateRangeField || 'created',
        gte: options.dateRangeFilter.startDate.getTime() || 0,
        lte: options.dateRangeFilter.endDate.getTime() || new Date().getTime()
      })
    }
    if (options.filter) addFilter(options.filter)

    const args = { ...options.args }
    if (!options.all) {
      args['$' + relField] = 'String!'
    }

    if (filter) {
      args.filter = filter
    }

    return generateGql({
      [getItemsMethodName]: {
        args,
        select: selectionFromFields(gqlFields)
      }
    })
  }, [
    entity,
    getItemsMethodName,
    gqlFields,
    options.all,
    options.args,
    options.dateRangeField,
    options.dateRangeFilter,
    options.filter,
    relField
  ])

  const variables = useMemo(() => (options.all ? {} : { [relField]: relValue }), [options.all, relField, relValue])

  const runEffect = useLazyEffect()

  const onLoad = options.onLoad

  const {
    data: dataItems,
    loading: loadingItems,
    refetch: refetchItems
  } = useQuery(gqlGetList, {
    pollInterval: isPageVisible ? (options.pollInterval !== undefined ? options.pollInterval : 8000) : 0,
    skip: options.skip || (relValue == null && options.filter == null),
    onCompleted:
      onLoad &&
      ((data) => {
        runEffect(() => onLoad(data[getItemsMethodName]))
      }),
    variables
  })

  const dataField = options.single ? 'item' : 'items'

  return {
    isLoading: loadingItems,
    [dataField]: dataItems && dataItems[getItemsMethodName],
    refresh: refetchItems
  } as GqlQuery<T>
}
