import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { GqlZeroReport, ReportGqlQuery } from './types'
import { useZeroApiContext } from '../../context'
import { generateGql } from '../../gql'
import { useEntityFieldsMap } from '../../gql-fields/useEntityFieldsMap'
import { Args, Field, FieldMap, FormQueryProps, GqlSelection } from '../../types'

interface FormQuery<T extends object> {
  fields: Field<any>[]
  fieldsMap: FieldMap
  query: ReportGqlQuery<GqlZeroReport<T>>
}

interface Props extends FormQueryProps {
  dateFrom?: number
  dateTo?: number
  filters?: Record<string, string[]>
}

export function useReportGql<T extends Record<string, unknown>>(props: Props): FormQuery<T> {
  const context = useZeroApiContext()

  const queryName = props.entity

  const fieldsMap = useEntityFieldsMap(props)

  const fields = useMemo(() => Object.values(fieldsMap).filterNotNull(), [fieldsMap])

  const filters = useMemo(() => {
    if (!props.filters) return []
    return Object.entries(props.filters).map(([key, options]) => ({ name: key, selection: options }))
  }, [props.filters])

  const contextRelationField = `${context.name.toLowerCase()}Id`

  const args = useMemo<Args>(() => {
    return {
      [contextRelationField]: context.id,
      lang: context.lang,
      dateFrom: props.dateFrom ?? 0,
      dateTo: props.dateTo ?? Date.now() * 1000,
      filters
    }
  }, [context.id, context.lang, contextRelationField, filters, props.dateFrom, props.dateTo])

  const select = useMemo<GqlSelection>(
    () => ({
      filters: {
        name: true,
        title: true,
        options: {
          _id: true,
          id: true,
          title: true
        },
        selection: true
      },
      group: {
        format: true,
        name: true,
        title: true,
        selected: true,
        hidden: true
      },
      subTotals: {
        name: true,
        title: true,
        selected: true,
        format: true,
        hidden: true
      },
      totalsGroup: {
        totals: {
          name: true,
          title: true,
          selected: true,
          hidden: true
        },
        defaultSubTotals: true,
        groupBy: {
          name: true,
          title: true,
          headerTitle: true,
          values: {
            title: true,
            value: true
          }
        }
      },
      title: true,
      documentHeaders: {
        title: true,
        value: true
      },
      headers: {
        name: true,
        title: true,
        format: true,
        hidden: true,
        children: {
          name: true,
          title: true,
          format: true,
          hidden: true
        }
      },
      results: fields
        .filter((it) => !it.virtual && !it.derived && !it.id)
        .toMapBy(
          (it) => it.name,
          () => true
        )
    }),
    [fields]
  )

  const queryGql = useMemo(() => {
    return generateGql({
      [queryName]: { args, select }
    })
  }, [args, queryName, select])

  const {
    data,
    loading: isLoading,
    refetch: refresh
  } = useQuery(queryGql, {
    pollInterval: 16000,
    fetchPolicy: 'cache-and-network'
  })

  const query = useMemo<ReportGqlQuery<GqlZeroReport<T>>>(() => {
    return {
      isLoading,
      refresh,
      data: data?.[queryName]
    }
  }, [data, isLoading, queryName, refresh])

  return { fields, fieldsMap, query }
}
