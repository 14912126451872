import { Field } from '../../types'
import { callOrGet, ifNull } from '../../utils'

export const getFormData = (fields: Field<any>[], itemData: any) => {
  const data = {}
  const extractData = (item: any, outData: any) => (field?: Field<any>) => {
    if (!field) return

    const value = ifNull(item ? item[field.name] : null, callOrGet(field.value, item), callOrGet(field.default, item))

    if (field.list) {
      outData[field.name] = value.map((itemValue) => {
        const nestedData = { _agrItems: itemValue._agrItems }
        Object.values(field.childs ?? {})
          .filterNotNull()
          .forEach(extractData(itemValue, nestedData ?? {}))
        return nestedData
      })
    } else if (field.nested) {
      const nestedData = {}
      outData[field.name] = nestedData
      Object.values(field.childs ?? {})
        .filterNotNull()
        .forEach(extractData(value, nestedData ?? {}))
    } else {
      outData[field.name] = value
    }
  }

  fields.forEach(extractData(itemData, data))

  return data
}
