import { getFieldName } from './getFieldName'
import { Field, GqlSelection } from '../../types'


export const selectionFromFields: (fields: Field<any>[]) => Record<string, GqlSelection | true> = (fields) => {
  return fields.toMapBy<string, GqlSelection | true>(getFieldName, (field: Field<any>) => {
    if (!field.subSelection && !field.childs) {
      return true
    }
    if (!field.childs && field.subSelection) {
      return field.subSelection
    }
    if (!field.subSelection && field.childs) {
      return selectionFromFields(Object.values(field.childs).filterNotNull())
    }
    return { ...field.subSelection, ...selectionFromFields(Object.values(field.childs ?? {}).filterNotNull()) }
  })
}
