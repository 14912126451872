import React, { useState, useCallback } from 'react'

import { EditText } from './EditText'
import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet } from '../utils'

interface FormFieldProps extends GqlTabletField<number> {
  minValue?: number | ((item: any) => number)
  maxValue?: number | ((item: any) => number)
  decimals?: number
  currency?: boolean
  hint?: string
  type?: React.InputHTMLAttributes<unknown>['type']
}

export const EditTextFloat: GqlComponent<FormFieldProps, number> = (props) => {
  const [error, setError] = useState(false)

  const minValue = callOrGet(props.minValue, props.item ?? props.rowData)
  const maxValue = callOrGet(props.maxValue, props.item ?? props.rowData)
  const { nullable, onChange } = props

  const onChangeValue = useCallback(
    (value: any | null) => {
      if (value == null && !nullable) {
        setError(true)
        return true
      } else if (isNaN(value)) {
        setError(true)
        return true
      } else if (minValue != null && value < minValue) {
        setError(true)
        return true
      } else if (maxValue != null && value > maxValue) {
        setError(true)
        return true
      }
      if (error) {
        setError(false)
      }
      return onChange?.(value)
    },
    [error, nullable, maxValue, minValue, onChange]
  )

  return <EditText {...props} error={error} onChange={onChangeValue} decimalNumber />
}

EditTextFloat.gql = 'Float'

EditTextFloat.validate = (props: FormFieldProps, value: number) => {
  if (props.minValue !== undefined && value < callOrGet(props.minValue, props.item)) return true
  return props.maxValue !== undefined && value > callOrGet(props.maxValue, props.item)
}

EditTextFloat.align = 'right'
EditTextFloat.noPadding = true

EditTextFloat.render = (props, value, row) => {
  if (props.currency) {
    return value.formatCurrency(row.currency)
  }
  const decimals = props.decimals !== undefined ? props.decimals : 2

  const multiplier = Math.pow(10, decimals)
  return Math.floor(value * multiplier) / multiplier
}
