import React, { useCallback } from 'react'

import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import { Chip } from '@mui/material'

import { GroupOptionsSelection } from './useReportGroupOptions'
import { useTranslation } from '../../../translations'
import { ReportGroupOptions } from '../types'
import { ReportControls } from '../ui/ReportControls'

interface Props {
  name: string
  icon?: React.ReactNode
  options?: ReportGroupOptions[]
  onChange: (selection: GroupOptionsSelection) => void
}

export const GqlReportGroupOptionsList: React.FC<Props> = ({ name, icon, options, onChange }) => {
  const translate = useTranslation()

  const onToggle = useCallback(
    (item: ReportGroupOptions) => {
      onChange(
        options?.toMapBy(
          (it) => it.name,
          (it) => (it === item ? !item.selected : it.selected)
        ) ?? {}
      )
    },
    [onChange, options]
  )

  if (!options?.length) return null

  return (
    <ReportControls title={translate(name)} icon={icon}>
      {options.map((option) => (
        <Chip
          key={option.name}
          label={option.title}
          deleteIcon={option.selected ? <DoneIcon /> : <AddIcon />}
          onClick={() => onToggle(option)}
          onDelete={() => onToggle(option)}
          variant={option.selected ? 'filled' : 'outlined'}
          color={option.selected ? 'default' : 'default'}
        />
      ))}
    </ReportControls>
  )
}
