import React, { useEffect, useMemo, useState } from 'react'

import { TextField, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { HiddenField } from './HiddenField'
import { useTranslation } from '../translations'
import { GqlComponent, GqlTabletField } from '../types'
import { callOrGet, useResetableState } from '../utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-grid',
    margin: theme.spacing(1),
    minWidth: '18ch',
    flexGrow: 1,
    '& > .MuiTextField-root': {
      display: 'grid',
      width: '-webkit-fill-available'
    }
  },
  endAddorment: {
    opacity: 0.7,
    fontSize: 11,
    whiteSpace: 'nowrap',
    marginLeft: -36
  }
}))

interface FormFieldProps extends GqlTabletField<number> {
  currency?: string
  currencyField?: string
}

export const CurrencyAmountField: GqlComponent<FormFieldProps, number> = (props: FormFieldProps) => {
  const classes = useStyles()

  const translate = useTranslation()

  const item = props.item || props.rowData
  const propsValue = props.name && props.value && typeof props.value === 'object' ? props.value[props.name] : callOrGet(props.value, item)
  const value = propsValue ?? callOrGet(props.default, item)

  const currency: string = props.currency ?? item[props.currencyField ?? 'currency']?.toString() ?? 'EUR'

  const [isOpen, setIsOpen] = useState(false)
  const [rawInputValue, setRawInputValue] = useResetableState(currency ? `${value} ${currency}` : value.toString(), [item?.id])

  const onChange = props.onChange !== undefined ? props.onChange : () => {}

  useEffect(() => {
    if (props.disabled && value) {
      setRawInputValue(value.toString())
    }
  }, [props.disabled, setRawInputValue, value])

  const valueParts = rawInputValue.split(' ')
  const inputAmount = parseFloat(valueParts[0])

  const fieldSize = props.size === 'dense' ? 'small' : 'medium'

  const inputValue = useMemo(() => {
    if (isOpen && rawInputValue.length === 0) return ''
    if (!isOpen && rawInputValue.length === 0) return '0'
    if (Number.isNaN(inputAmount)) return valueParts[0].replace(/[^\d.,-]/g, '')
    if (!isOpen) return inputAmount.formatCurrency?.(currency) ?? rawInputValue
    return valueParts[0].replace(/[^\d.,-]/g, '')
  }, [inputAmount, valueParts, isOpen, currency, rawInputValue])

  useEffect(() => {
    if (isNaN(inputAmount)) return
    if (propsValue === inputAmount) return
    item[props.name] = inputAmount

    onChange(inputAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputAmount])

  const endAdornment = useMemo(() => {
    if (isOpen && inputAmount) {
      return <span className={classes.endAddorment}>{currency}</span>
    }
    return null
  }, [classes.endAddorment, currency, inputAmount, isOpen])

  return (
    <TextField
      key={item?.id}
      className={classes.root}
      label={
        props.isForm
          ? React.isValidElement(props.label)
            ? props.label
            : translate(props.label?.toString() || props.name.snakeCase())
          : undefined
      }
      error={callOrGet(props.error, inputValue)}
      variant={props.isForm ? 'outlined' : 'standard'}
      size={fieldSize}
      sx={{ fontSize: props.fontSize ?? props.size === 'dense' ? 12 : 14 }}
      inputProps={{
        autoCorrect: 'off',
        autoCapitalize: 'off',
        style: { fontSize: props.fontSize ?? props.size === 'dense' ? 12 : 14 }
      }}
      fullWidth={props.fullWidth}
      InputProps={{ endAdornment }}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onChange={(e) => setRawInputValue(e.target.value)}
      value={inputValue}
      disabled={callOrGet(props.disabled, value, item)}
    />
  )
}
CurrencyAmountField.fields = (props) => {
  const fields = [<HiddenField key="amount" name={props.name} nullable={props.nullable} gql={props.gql || 'Float'} />]
  if (!props.currency) {
    fields.push(<HiddenField key="currency" name={props.currencyField || 'currency'} nullable={props.nullable} gql="String" />)
  }
  return fields
}
CurrencyAmountField.gql = 'Float'
CurrencyAmountField.align = 'right'

CurrencyAmountField.render = (props: FormFieldProps, rowValue: number, row: any) => {
  const currency: string = row.currency ?? row[row.currencyField ?? 'currency']?.toString() ?? 'EUR'

  const value = Math.round(callOrGet(rowValue, row))

  if (Number.isNaN(value)) return ''

  return value.formatCurrency(currency)
}
