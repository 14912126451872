/* eslint-disable no-unused-vars */
const transitionCyrLat: { [key: string]: string } = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  ѓ: 'g',
  е: 'e',
  ж: 'z',
  з: 'z',
  ѕ: 'z',
  и: 'i',
  ј: 'j',
  к: 'k',
  л: 'l',
  љ: 'l',
  м: 'm',
  н: 'n',
  њ: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  ќ: 'k',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'c',
  џ: 'dz',
  ш: 's',
  lj: 'l',
  sh: 's',
  nj: 'n',
  gj: 'g'
}

const Str = String
const Num = Number

export type PossibleKeysType = string | number | symbol

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Object {}

  interface String {
    snakeCase(): string

    camelCase(): string

    cyrillicToLatin(): string

    cyrillicLatinCompare(lang: string): string

    capitalize(): string

    capitalizeCamelCase(): string

    ifEmpty(otherValue: string): string
  }

  interface Number {
    switch<T>(options: { [key in number | string]: T }): T

    round(decimals: number, defNaN: number | undefined): number | undefined

    roundUpBy(step: number): number

    roundDownBy(step: number): number

    formatCurrency(currency: string): string

    atLeast(minValue: number): number

    atMost(maxValue: number): number

    closestTo(...values: number[]): number
  }

  interface Date {
    toISODate(): string
  }

  interface Array<T> {
    mapDistinct<R>(func: (t: T) => R): R[]

    distinctBy<R>(func: (t: T) => R): T[]

    first<R>(func: (t: T) => R | undefined): R

    firstOrNull<R>(func: (t: T) => R | undefined): R | undefined

    orderBy<R>(keyFunc: (t: T) => R): T[]

    orderByDesc<R>(keyFunc: (t: T) => R): T[]

    groupBy<K extends PossibleKeysType>(keyFunc: (t: T) => K): { [key in K]: T[] }

    groupBy(keyFunc: (t: T) => string): { [key: string]: T[] }

    groupBy(keyFunc: (t: T) => number): { [key: number]: T[] }

    joinOf(glue: string, func: (t: T) => string, defValue?: string): string

    count(predicate: (t: T) => boolean): number

    any(predicate: (t: T, index: number) => boolean): boolean

    none(predicate: (t: T) => boolean): boolean

    all(predicate: (t: T, index: number) => boolean): boolean

    sumOf(func: (t: T) => number): number

    maxOf<P>(func: (t: T) => P, initial?: P): P | undefined

    maxBy(func: (t: T) => number): T

    minOf<P>(func: (t: T) => P, initial?: P): P | undefined

    minBy(func: (t: T) => number): T

    filterNotNull(): NonNullable<T>[]

    toMapBy<S extends PossibleKeysType, R>(kf: (t: T) => S | null, vf?: (t: T) => R): { [key: string]: R }

    toNotNullMapBy<S extends PossibleKeysType, R>(kf: (t: T) => S | null, vf?: (t: T) => R | null): { [key: string]: R }

    toMapByAsync<S extends PossibleKeysType, R>(kf: (t: T) => S, vf: (t: T) => R): Promise<{ [key in PossibleKeysType]: R }>

    cleanUpObjects(predicate: (key: string) => boolean): void
  }

  interface JSON {
    stringifySafe(data: any, space?: string | number): string
  }
}

// Obj.prototype.let = function <T, R>(letBlock: (it: T) => R) {
//   console.log("letBlock", letBlock)
//   // return letBlock(this as T);
//   return {} as R
// };

// Obj.prototype.also = function <T>(alsoBlock: (it: T) => void) {
//   alsoBlock(this as T);
//   return this as T;
// };

// Obj.prototype.forEach = function <T>(block: (key: PossibleKeysType, value: any) => void) {
//   for (const k in this) {
//     if (this.hasOwnProperty(k)) {
//       block(k, this[k])
//     }
//   }
// }

export function objMap<T, R>(obj: { [key in PossibleKeysType]: T }, mapFunc: (key: PossibleKeysType, value: T) => R): R[] {
  const result: R[] = []
  for (const k in obj) {
    result.push(mapFunc(k, obj[k]))
  }
  return result
}

export function objMapValues<T, R>(
  obj: { [key in PossibleKeysType]: T },
  mapFunc: (key: PossibleKeysType, value: any) => R
): {
  [key in PossibleKeysType]: R
} {
  const result: {
    [key in PossibleKeysType]: R
  } = {}
  for (const k in obj) {
    result[k] = mapFunc(k, obj[k])
  }
  return result
}

Str.prototype.snakeCase = function () {
  return this.replaceAll(/([A-Z])/g, '_$1').toLowerCase()
}
Str.prototype.camelCase = function () {
  return this.substring(0, 1).toLowerCase() + this.substring(1)
}

Str.prototype.cyrillicToLatin = function () {
  let self = this.toLowerCase()
  for (const letter in transitionCyrLat) {
    // eslint-disable-next-line security/detect-non-literal-regexp
    self = self.replace(new RegExp(letter, 'g'), transitionCyrLat[letter])
  }
  return self
}
Str.prototype.cyrillicLatinCompare = function (lang: string) {
  let letter
  let self = this.toLowerCase()
  if (lang !== 'mk') {
    for (letter in transitionCyrLat) {
      // eslint-disable-next-line security/detect-non-literal-regexp
      self = self.replace(new RegExp(letter, 'g'), transitionCyrLat[letter])
    }
  } else {
    for (letter in transitionCyrLat) {
      // eslint-disable-next-line security/detect-non-literal-regexp
      self = self.replace(new RegExp(transitionCyrLat[letter], 'g'), letter)
    }
  }

  return self
}
Str.prototype.capitalizeCamelCase = function () {
  if (this.length <= 1) return this.toLocaleUpperCase()
  return this.charAt(0).toLocaleUpperCase() + this.slice(1).toLocaleLowerCase()
}
Str.prototype.capitalize = function () {
  if (this.length <= 1) return this.toLocaleUpperCase()
  return this.charAt(0).toLocaleUpperCase() + this.slice(1)
}
Str.prototype.ifEmpty = function (otherValue: string) {
  if (this.trim().length === 0) return otherValue
  return this.toString()
}
// Str.prototype.capitalizeAll = function () {
//     return this.split(/(\W+)/).map(w => w.capitalize()).join('');
// }
// Str.prototype.capitalizeSentence = function () {
//     return this.split(/([\.\?\!]\s*)/).map(w => w.capitalize()).join('');
// }

// Str.prototype.wordsUpTo = function (length) {
//     if (this.length <= length) return this;
//     var result = "";
//     let words = this.split(/([\.\?\!\s]+)/).map(w => w.capitalize()).forEach(word => {
//         if (result.length + word.length <= length) {
//             result += word;
//         }
//     });
//     return result.trim();
// }

Num.prototype.atLeast = function (minValue: number) {
  return Math.max(this as number, minValue)
}

Num.prototype.atMost = function (maxValue: number) {
  return Math.min(this as number, maxValue)
}

Num.prototype.closestTo = function (...values: number[]) {
  const thisVal = this as number
  if (values.length === 0) return thisVal
  let closest = values[0]
  for (let i = 1; i < values.length; i++) {
    if (Math.abs(values[i] - thisVal) < Math.abs(closest - thisVal)) {
      closest = values[i]
    }
  }
  return closest
}

Num.prototype.switch = function <T>(options: { [key in number | string]: T }) {
  return options[this as unknown as number] || options.default
}

Num.prototype.round = function (decimals: number, defNaN: number | undefined) {
  const multiplier = Math.pow(10, decimals)
  const value = Math.round((this as number) * multiplier) / multiplier
  return isNaN(value) ? defNaN || value : value
}

Num.prototype.roundUpBy = function (step: number) {
  return Math.ceil((this as number) / step) * step
}
Num.prototype.roundDownBy = function (step: number) {
  return Math.floor((this as number) / step) * step
}

const CURRENCIES = [
  'EUR',
  'MKD',
  'USD',
  'GBP',
  'CHF',
  'SEK',
  'NOK',
  'DKK',
  'CZK',
  'HUF',
  'PLN',
  'RON',
  'HRK',
  'TRY',
  'RUB',
  'BRL',
  'CNY',
  'CAD',
  'AUD',
  'JPY'
]

const currencies: { [key: string]: any } = {
  default: { currency: 'EUR', maximumFractionDigits: 2 },
  MKD: { style: 'currency', currency: 'MKD', maximumFractionDigits: 0 }
}

CURRENCIES.forEach((it) => {
  if (currencies[it]) return
  currencies[it] = { style: 'currency', currency: it }
})

const currencyFormatters: { [key: string]: (value: number) => string } = {}

const getCurrencyFormatter = (currencyName: string) => {
  const cache = currencyFormatters[currencyName]
  if (cache) return cache

  // noinspection SuspiciousTypeOfGuard
  const currency = (typeof currencyName === 'string' && currencies[currencyName]) || currencies.default
  const numFormatter = new Intl.NumberFormat('de', currency)

  const formatter = (value: number) => numFormatter.format(value).replace('MKD', 'д').replace(' ', '')
  currencyFormatters[currencyName] = formatter
  return formatter
}

Num.prototype.formatCurrency = function (currencyName: string) {
  if (Number.isNaN(this)) return ''
  return getCurrencyFormatter(currencyName)(this as number)
}

const Arr = Array

// Arr.prototype.plus = function (...arrays) {
//     if (arrays === undefined || arrays.length === 0 || (arrays.length === 1 && arrays[0] === undefined)) return this;
//     const result = [...this];
//     arrays.forEach(array => array && result.push(array));
//     return result;
// }

// Arr.prototype.get = function (key, defValue) {
//     return this[key] || defValue;
// }

Arr.prototype.cleanUpObjects = function (predicate: (key: string) => boolean) {
  if (this.length === 0) return
  this.forEach((it) => {
    Object.keys(it).forEach((key) => {
      if (predicate(key)) delete it[key]
    })
  })
}

Arr.prototype.groupBy = function (keyFunction: ((t: any) => string) | ((t: any) => number) | ((t: any) => any)) {
  const groups: { [key in string | number]: any[] } = {}
  this.forEach(function (el) {
    const key: string | number = keyFunction(el)
    const keyArr = groups[key]
    if (keyArr === undefined) {
      groups[key] = [el]
    } else {
      keyArr.push(el)
    }
  })
  return groups
}

Arr.prototype.joinOf = function <T>(glue: string, valFunction: (t: T) => string, defValue?: string) {
  if (this.length === 0) return defValue || ''
  let result: string | undefined
  this.forEach((item) => {
    if (result === undefined) {
      result = valFunction(item)
    } else {
      result += glue + valFunction(item)
    }
  })
  return result || defValue || ''
}

Arr.prototype.filterNotNull = function () {
  return this.filter((it) => it != null)
}

Arr.prototype.toMapBy = function <T, S extends PossibleKeysType, R>(keyFunction: (t: T) => S | null, valFunction?: (t: T) => R) {
  const result: { [key in PossibleKeysType]: any } = {}
  this.forEach(function (el) {
    const key = keyFunction(el)
    if (key === null) return
    result[key] = valFunction ? valFunction(el) : el
  })
  return result
}

Arr.prototype.toNotNullMapBy = function <T, S extends PossibleKeysType, R>(
  keyFunction: (t: T) => S | null,
  valFunction?: (t: T) => R | null
) {
  const result: { [key in PossibleKeysType]: any } = {}
  this.forEach(function (el) {
    const key = keyFunction(el)
    if (key === null) return
    const val = valFunction ? valFunction(el) : el
    if (val === null) return
    result[key] = val
  })
  return result
}

Arr.prototype.toMapByAsync = async function <T, S extends PossibleKeysType, R>(keyFunction: (t: T) => S, valFunction: (t: T) => R) {
  const result: { [key in PossibleKeysType]: any } = {}
  if (valFunction) {
    for (const el of this) {
      result[keyFunction(el)] = await valFunction(el)
    }
  } else {
    for (const el of this) {
      result[keyFunction(el)] = el
    }
  }

  return result
}

const compare = (a: any, b: any) => {
  if (a === b) return 0
  if (a > b) return 1
  return -1
}
Arr.prototype.mapDistinct = function <T, R>(func: (t: T) => R): R[] {
  const result = new Set<R>()
  this.forEach((item) => {
    result.add(func(item))
  })
  return Array.from(result)
}
Arr.prototype.distinctBy = function <T, R>(func: (t: T) => R): T[] {
  const result = new Set<R>()
  return this.filter((it) => {
    const key = func(it)
    if (result.has(key)) return false
    result.add(key)
    return true
  })
}
Arr.prototype.firstOrNull = function <T, R>(func: (t: T) => R | undefined): R | undefined {
  for (const item of this) {
    const result = func(item)
    if (result !== undefined) return result
  }
  return undefined
}
Arr.prototype.first = function <T, R>(func: (t: T) => R | undefined): R {
  for (const item of this) {
    const result = func(item)
    if (result !== undefined) return result
  }
  throw Error('There is no first item in ' + this)
}
Arr.prototype.orderBy = function <T, R>(keyFunction: (t: T) => R) {
  return [...this].sort((a, b) => compare(keyFunction(a), keyFunction(b)))
}
Arr.prototype.orderByDesc = function <T, R>(keyFunction: (t: T) => R) {
  return [...this].sort((a, b) => compare(keyFunction(b), keyFunction(a)))
}

Arr.prototype.any = function <T>(predicate: (t: T, index: number) => boolean) {
  let index = 0
  for (const item of this) {
    if (predicate(item, index++)) return true
  }
  return false
}

Arr.prototype.all = function <T>(predicate: (t: T, index: number) => boolean) {
  let index = 0
  for (const item of this) {
    if (!predicate(item, index++)) return false
  }
  return true
}

Arr.prototype.none = function <T>(predicate: (t: T) => boolean) {
  for (const item of this) {
    if (predicate(item)) return false
  }
  return true
}

Arr.prototype.count = function <T>(predicate: (t: T) => boolean) {
  let count = 0
  this.forEach((item) => {
    if (predicate(item)) count++
  })
  return count
}

Arr.prototype.sumOf = function <T>(valFunction: (t: T) => number) {
  let sum = 0
  this.forEach((item) => {
    sum = sum + valFunction(item)
  })
  return sum
}

Arr.prototype.maxOf = function f<T, P>(valFunction: (t: T) => P, initial?: P) {
  let max = initial
  this.forEach((item) => {
    const val = valFunction(item)
    if (max == null || val > max) {
      max = val
    }
  })
  return max
}

Arr.prototype.maxBy = function <T>(valFunction: (t: T) => number) {
  let max: number | undefined
  let maxItem
  this.forEach((item) => {
    const val = valFunction(item)
    if (max === undefined || val > max) {
      max = val
      maxItem = item
    }
  })
  return maxItem
}

Arr.prototype.minOf = function <T, P>(valFunction: (t: T) => P, initial?: P) {
  let min = initial
  this.forEach((item) => {
    const val = valFunction(item)
    if (min == null || val < min) {
      min = val
    }
  })
  return min
}

Arr.prototype.minBy = function <T>(valFunction: (t: T) => number) {
  let min: number | undefined
  let minItem
  this.forEach((item) => {
    const val = valFunction(item)
    if (min === undefined || val < min) {
      min = val
      minItem = item
    }
  })
  return minItem
}

// Arr.prototype.minOf = function (valFunction, initialMax) {
//     var max = initialMax;
//     this.forEach(item => {
//         let val = valFunction(item);
//         if (max === undefined || val < max) {
//             max = val;
//         }
//     })
//     return max;
// }

// Arr.prototype.minBy = function (valFunction) {
//     var max = undefined;
//     var maxItem = undefined;
//     this.forEach(item => {
//         let val = valFunction(item);
//         if (max === undefined || val < max) {
//             max = val;
//             maxItem = item;
//         }
//     })
//     return maxItem;
// }

// Arr.prototype.mapRange = function (start, end, valFunction) {
//     if (start < 0) throw Error("start should be greater or equal to zero", start);
//     if (end > this.length) throw Error("end should be less or equal to the length", start);
//     let results = [];
//     for (var i = start; i < end; i++) {
//         results.push(valFunction(this[i], i));
//     }
//     return results;
// }

// Arr.prototype.reduceRange = function (start, end, callback, initialVal) {
//     if (start < 0) throw Error("start should be greater or equal to zero", start);
//     if (end > this.length) throw Error("end should be less or equal to the length", start);
//     let acc = initialVal;
//     for (var i = start; i < end; i++) {
//         acc = callback(acc, this[i], i)
//     }
//     return acc;
// }

// Arr.prototype.reducePromise = function (callback, start = 0, end) {
//     if (start < 0) throw Error("start should be greater or equal to zero", start);
//     if (end === undefined) end = this.length;
//     if (end > this.length) throw Error("end should be less or equal to the length", start);
//     var acc = undefined;
//     for (var i = start; i < end; i++) {
//         let item = this[i];
//         if (acc === undefined) {
//             acc = callback(item, i);
//             continue;
//         }
//         acc = acc.then(() => callback(item, i));
//     }
//     return acc;
// }

// Arr.prototype.removeBy = function (predicate) {
//     let index = this.findIndex(predicate);
//     if (index < 0) return undefined;
//     return this.splice(index, 1)[0];
// }

// Arr.prototype.distinct = function () {
//     return this.filter((v, i, self) => self.indexOf(v) === i);
// }

// Arr.prototype.distinctBy = function (keyFunction) {
//     let set = new Set();
//     return this.filter((v) => {
//         let key = keyFunction(v);
//         if (set.has(key)) return false;
//         set.add(key);
//         return true;
//     });
// }

// Arr.prototype.move = function (from, to) {
//     if (from < 0 || from >= this.length) return this;
//     if (to < 0 || to >= this.length) return this;
//     this.splice(to, 0, this.splice(from, 1)[0]);
//     return this;
// }

const Dat = Date
Dat.prototype.toISODate = function () {
  return this.getFullYear() + '-' + (this.getMonth() + 1) + '-' + this.getDate()
}

// if (String.prototype.replaceAll === undefined) {
//     Str.prototype.replaceAll = function (search, replace) {
//         return this.replace(new RegExp(search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace)
//     }
// }

JSON.stringifySafe = (data: any, space?: number) =>
  JSON.stringify(data, (key: string, value: any) => (key.charAt(0) === '_' ? undefined : value), space)
